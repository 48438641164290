<template>
	<div id="app" >
		<!-- 移动端的摇杆 -->
		<PeachJoystick ref="PeachJoystick1"   :options="options" @moveant="moveant" v-if="!computer&&$store.state.isenter" @move="move" />
		<router-view ></router-view>
		<div ref="ddd" id="canvas_3d" class="blocks"></div> 
		<!-- 对话框 (公共区域) v-if="$route.meta.place"-->
		<dialogBox v-if="$store.state.isenter" ></dialogBox>
		<!-- 全局切换房间提示框 -->
		<warningToggle ></warningToggle>
		<!-- 全局的加载组件...mapState('VirtualDressing',['loadflag']), -->
		<loadcomme ></loadcomme>
		<!-- 全局提示信息 -->
		<tooltip></tooltip>
		<!-- 私人语音房间 -->
		<div  v-if="privatevoice" class="private_left_bottom">{{$t('tip.tip')}}</div>
		<!-- 走路音频 -->
		<!-- <audio ref="walkaudio" autoplay loop="loop"/> -->
	</div>
</template>
<script>
	import createPlayer from '@/util/createPlayer'
	// 对话框
	import dialogBox from '@/components/commonui/dialogBox.vue'
	// 切换提示
	import warningToggle from '@/components/commonui/warningToggle.vue'
	import PeachJoystick from '@/components/joystick/index.vue'
	// 全局提示信息
	import tooltip from '@/components/commonui/tooltip.vue'
	import loadcomme from '@/components/commonui/loadcomme.vue'
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'ThreeTest',
		data() {
			return {
				computer:false,
				options: {
					padding: 120
				}, //摇杆配置
				audioWalkElement:null
			}
		},
		computed: {
			...mapState(['publicWebsite']),
			...mapState('zimStore', ['playingAudiouser','privatevoice']),
			...mapState('walkingSound',['new_media_player','new_media_option'])
		},
		watch: {
		    playingAudiouser(newMessage) {
				console.log(newMessage)
				createPlayer.changeplayingArr(newMessage)
		    },
			new_media_option(newMessage){
				if(this.new_media_player){
					this.audioWalkElement.src =this.publicWebsite + this.new_media_option
					// this.audioWalkElement.src = this.new_media_option
				}
			},
			new_media_player(newMessage){
				// alert(1)
				if(!newMessage){
					this.audioWalkElement.pause();
				}else{
					// console.log(22223333)
					this.audioWalkElement.src =this.publicWebsite + this.new_media_option
					// this.audioWalkElement.src =this.new_media_option
					this.audioWalkElement.muted= false;
					this.audioWalkElement.paused&&this.audioWalkElement.play();	
				}
			}
		},
		components: {
			dialogBox,
			warningToggle,
			PeachJoystick,
			tooltip,
			loadcomme
		},
		mounted() {
			// 判断是否为电脑
			this.computer = !navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i);
			this.$store.commit('changecomputer', this.computer)
			this.init();
			// 判断当前是否已经连接了zego，连接了，刷新后需要重新连接一次
			if(sessionStorage.getItem('oneidname')){
				let user =  {
					userID: sessionStorage.getItem('username'),//当前用户id 唯一
					userName:sessionStorage.getItem('oneidname'),  //用户
				}
				this.loginZEGO(user)
			}
			this.$nextTick(()=>{
				console.log(this)
			})
			setTimeout(()=>{
				 console.log(this.$refs.PeachJoystick1,this,222)
			},100)
			// console.log(this.$refs.PeachJoystick1,this,222)
			// 开启语音房间监听
			this.$store.dispatch('zimStore/onlisten')
		},
		updated() {
			// 在每个页面加载完成时执行以下代码
			window.history.pushState(null, null, window.location.href);
			
		},
		beforeDestroy() {
			 window.pinus.disconnect((data)=>{
				 console.log(data)
			 })
			this.$store.dispatch('zimStore/logout')
		},
		methods: {
			//摇杆事件
			move(e) {
				window.viewer.joystickMove(e);
			},
			// 摇杆回到中心
			moveant() {
				
				window.viewer.joystickMoveEnd();
			},
			// 登录语音房间和文本房间
			loginZEGO(user){
				this.$store.dispatch('zimStore/loginroom',user)
				// this.$store.dispatch('zimStore/login',user).then(login=>{
				// 	if(login){
				// 		this.$store.dispatch('dialog/onListenerZIM')
				// 		// this.$store.dispatch('zimStore/createRoom').then(res=>{}).catch((e)=>{});
				// 	}else{
				// 		this.tip = "connection fail"
				// 	}
				// })
			},
			forceLandscapeScreenHandle() {
				setTimeout(()=>{
					const body = document.getElementsByTagName('body')[0];
					const html = document.getElementsByTagName('html')[0];
					const width = window.innerHeight;
					const height = window.innerWidth;
					const width1 =document.documentElement.clientHeight ;
					const height1 = document.documentElement.clientWidth ;
					const max = width > height ? width1: height1;
					const min = width > height ? height1: width1;
					// console.log('window',width,height,'document',width1,height1,'最后结果宽',max)
					body.style.width = max + "px";
					body.style.height = min + "px";
				},10)
			},
			onWindowSizeChanged() {
				window.addEventListener("resize", this.forceLandscapeScreenHandle)
				window.addEventListener('DOMContentLoaded',  this.forceLandscapeScreenHandle, false);
				document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
				    // 通过下面这个API隐藏底部导航栏
				    WeixinJSBridge.call('hideToolbar');
				});
				if(!this.audioWalkElement){
					this.audioWalkElement= document.createElement('audio');
					this.audioWalkElement.setAttribute('id', 'audio');
					this.audioWalkElement.setAttribute('autoplay', 'autoplay');
					this.audioWalkElement.setAttribute('loop', 'loop');
					this.audioWalkElement.setAttribute('crossorigin', '');
					this.audioWalkElement.src =this.publicWebsite + this.new_media_option
					this.audioWalkElement.muted= true;
				}
				if(!this.computer){
					window.addEventListener('click',()=>{
						this.audioWalkElement.play();
						setTimeout(()=>{
							if(!this.new_media_player){
								this.audioWalkElement.muted= false;
								this.audioWalkElement.pause();
							}
						},100)
					})
				}
					
				
				
			},
			init() {
				this.forceLandscapeScreenHandle();
				this.onWindowSizeChanged();
			},
		},

	}
</script>

<style lang="less">
	#app {
		width: 100%;
		height: 100%;
		overflow: hidden;
		user-select: none;
		position: relative;
		z-index: 100;
	}
	#canvas_3d {
			 position: fixed;
			 left: 0;
			 top: 0;
			 width: 100%;
			 height: 100%;
			 z-index: -1;
			 canvas{
				 position: relative;
				 z-index: -1;
			 }
			
	}
	
	html {
		/*用于 获取 屏幕的可视宽高*/
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	body {
		/*让 body 初始 width 和 height 就 等于 页面可视区域的 宽高*/
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	@media screen and (orientation:portrait) {

		/*竖屏样式*/
		body {
			transform-origin: 0 0;
			transform: rotateZ(90deg) translateY(-100%);
		}
	}
</style>
