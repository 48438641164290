import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/lang/index.js'
import user from './mode/user'
import dialog from './mode/dialog.js'
import VirtualDressing from './mode/VirtualDressing.js'
import placeInfo from './mode/placeInfo.js'
import zimStore from './mode/zimStore.js'
import walkingSound from './mode/walkingSound.js'
Vue.use(Vuex)
let time;
export default new Vuex.Store({
	state: {
		computer:true,//是否位pc端
		loadnum: 0, //当前进度条进度
		loading: true, //进度条是否出现
		isenter:false,//是否进入了场景
		videoarr:[],//当前视频
		showimg:false,//控制图片显示框是否显示
		imgurl:false,
		videourl:false,
		commcontent:null,//商品详情内容
		showcomm:false,
		statetyping:false,//当前是否在打字
		audioBG:false,//背景音乐,当前是否在播放
		giveup:false,//当前用户是否点赞
		isscreenfull:false,//当前是否是全屏
		giveupnum:-1,
		firstload:false,
		lastrouter:null,
		firstPerson:false,//人称
		animation:true,//false 跑 true 走
		animationJump:false,//是否在跳
		publicWebsite: window.weburl?'https://tencent-bucket-arx-1300332380.cos.accelerate.myqcloud.com/' : 'https://tencent-bucket-arx-1300332380.cos.ap-singapore.myqcloud.com/',//全球加速
		emotjiArr:[],
		tooltipshow:{
			state:false,
			content:'这是测试用的一句话',
			color:'red'
		},
		alertshow:{
			show:false, //当前是否已经展示出来了
			start:true, //当前是否是展示出来的状态
			toname:null,
			toobj:{}
		},
	},
	mutations: {
		changeemotjiArr(state,data){
			state.emotjiArr = data
		},
		changeanimationJump(state,data){
			// console.log(data)
			state.animationJump = data
		},
		changeanimation(state,data){
			state.animation = !state.animation
		},
		changefirstPerson(state,data){
			state.firstPerson = data
		},
		changelastrouter(state,data){
			state.lastrouter = data
		},
		changtooltipshow(state,data){
			state.tooltipshow= data
			clearTimeout(time)
			time= setTimeout(()=>{
				state.tooltipshow= {
					state:false,
					content:'',
					color:'red'
				}
			},2000)
		},
		changeisenter(state,data){
			state.isenter= data
		},
		changefirstload(state,data){
			state.firstload= data
		},
		changegiveupnum(state,data){
			state.giveupnum = data
		},
		changeaudioBG(state,data){
			state.audioBG = data
		},
		changeisscreenfull(state,data){
			state.isscreenfull = data
		},
		changegiveup(state,data){
			state.giveup = data
		},
		onchangestatetyping(state,data){
			state.statetyping = data
		},
		changecomputer(state, data){
			state.computer = data
			
		},
		changecommcontent(state, data) {
			state.commcontent = data
		},
		changeshowcomm(state, data) {
			state.showcomm = data
		},
		// 隐藏或显示提示框
		changealertshow(state, data) {
			// console.log(data)
			state.alertshow.show = data
		},
		// 改变要前往的场景名
		changealerttoname(state, data) {
			state.alertshow.toname = data
		},
		changealertstart(state,data){
			state.alertshow.start = data 
		},
		changealertshowtoobj(state,data){
			// console.log(data)
			state.alertshow.toobj = data 
		},
		// 第一次进入进度条
		changloading(state, data) {
			state.loading = data
		},
		// 改变当前进度
		changloadnum(state, data) {
			state.loadnum = data
		},
		changeimgurl(state,data){
			// console.log('打开图片',data)
			state.imgurl = data
		},
		changevideourl(state,data){
			state.videourl = data
		},
		changeshowimg(state,data){
			// console.log('打开图片',data)
			state.showimg = data
			if(!data){
				state.imgurl =false;
				state.videourl =false;
			}
		},
	},
	actions: {},
	modules: {
		user,
		dialog,
		VirtualDressing,
		placeInfo,
		zimStore,
		walkingSound
	}
})
