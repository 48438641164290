// 第三方的promise风格的http库
import axios from "./http.js";

//请求示例
//get
export const get = (url, data, config) => {
	return axios({
		url: url,
		method: "get",
		data,
		config: config,
	});
};
//post
export const post = (url, data, config) => {
	return axios({
		url: url,
		method: "post",
		data:data,
		config: config,
	});
};
