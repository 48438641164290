<template>
	<div  v-show="$store.state.tooltipshow.state" class="my-tooltip" :style="{color:$store.state.tooltipshow.color} ">
		{{ $store.state.tooltipshow.content }}
	</div>
</template>

<script>
	export default {
		
	};
</script>

<style scoped>
	.my-tooltip {
		pointer-events: none;
		position: 'fixed';
		left: 0;
		bottom: 0;
		z-index: 10001;
		display: inline-block;
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		/* padding: 20px; */
		color: red;
		transition: all 4s ease-out;
	}

	.my-tooltip:hover .my-tooltip {
		display: block;
	}
</style>