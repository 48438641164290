// 加载主控人物
import * as THREE from "three";
import store from '@/store'
import {
	loadDraco,
} from "@/util/compass.js"

export default class currentPlayer {
	constructor() {
		this.playermodel = null; //需要展示的人
		this.player =null;
	}
	// 加载主控人物所有配置项
	initallplayer(option,callback){
		// if(!option){
		// 	option = JSON.parse(sessionStorage.getItem('Virtualpalyer'))
		// }
		option =option.replaceAll('arweb-virtualshop',store.state.publicWebsite +'arweb-virtualshop')
		option = JSON.parse(option)
		
		this.player = JSON.parse(option.modelurl)
		// 加载基础人物模型
		loadDraco(this.player.modelurl,(mesh)=>{
			mesh.scene.name = 'body'
			// this.playermodel = mesh.scene
					// console.log(mesh)
			if( option.coat&&option.coat != 'null' ){ 
				let itemcontent = JSON.parse( option.coat)
				this.changshoe(itemcontent,null,mesh.scene)
			}
			if (option.trousers&&option.trousers != 'null'){
				let itemcontent = JSON.parse( option.trousers)
				this.changshoe(itemcontent,null,mesh.scene)
			}
			if( option.eyebrow&&option.eyebrow != 'null'){
				let itemcontent = JSON.parse( option.eyebrow)
				this.changshoe(itemcontent,null,mesh.scene)
			}
			if(option.shoe&&option.shoe != 'null'){
				let itemcontent = JSON.parse( option.shoe)
				this.changshoe(itemcontent,null,mesh.scene)
			}
			// 眼睛
			if(option.eyes&&option.eyes != 'null' ){
				let itemcontent = JSON.parse( option.eyes)
				this.setAdImages(itemcontent,mesh.scene)
			}
			
			// 头发
			if(option.hair&&option.hair != 'null'){
				let itemcontent = JSON.parse( option.hair)
				// console.log(option.haircolor)
				this.changHair(itemcontent,option.haircolor,mesh.scene)
			}
			// 脸
			if(option.feature&&option.feature != 'null' ){
				let itemcontent = JSON.parse( option.feature)
				this.changeFace(itemcontent,option.skin,mesh.scene)
			}
			// 皮肤
			if(option.skin&&option.skin != 'null'){
				// console.log(option,option.skin)
				this.setSkinColor(option.skin,'Skin',mesh.scene)
			}
			callback(mesh.scene,mesh)
			// 替换上衣
			// this.changeimage('',name,item)
		})
	}
	//  换眼睛贴图
	async setAdImages(item,model) {
		let playmodel = model? model:this.playermodel;
		let modeleyes =  await this.findByNameMesh(item.type,playmodel)
		let texture = new THREE.TextureLoader().load(item.content);
		texture.encoding = THREE.sRGBEncoding;
		texture.flipY = false;
		let mat = new THREE.MeshLambertMaterial({
			map: texture,
		});
		modeleyes.material = mat;
		modeleyes.name = item.namemodel
	}
	// 换衣服贴图
	async changeimage(item,model1){
		let playmodel = model1? model1:this.playermodel
		let model =  await this.findByNameMesh(item.type,playmodel)
		let image = new Image()
		image.src = item.content;
		image.setAttribute('crossorigin', 'anonymous');
		// let model = scene.getObjectByName(modelname)
		image.onload = function () {
			model.material.map.image = image;
			model.material.map.needsUpdate = true;
			model.name = item.namemodel
		}
	}
	async setSkinColor(imageurl,name,model){
		let playmodel = model? model:this.playermodel
		let newshoe =  await this.findByNameMesh(name,playmodel)
		newshoe.material.color.set( imageurl )
	}
	// 替换模型
	changshoe(item,callback,model){
		!model &&store.commit('VirtualDressing/changeloadflag',true)
		loadDraco(item.content,async (mesh)=>{
			let playmodel = model? model:this.playermodel
			// 新鞋子 
			let newshoe =  await this.findByNameMesh(item.type,mesh.scene)
			
			// 要替换的鞋子
			let shoe = await this.findByNameMesh(item.type,playmodel)
			// console.log(111111111111111111,newshoe,playmodel,shoe)
			if(shoe){
				let op = shoe.bindMatrix
				newshoe.bind(shoe.skeleton,op);
				playmodel.remove(shoe)
				playmodel.add(newshoe)
				callback&&callback()
			}
			!model &&store.commit('VirtualDressing/changeloadflag',false)
		})
	}
	changeFace(item,color,model){
		let playmodel = model? model:this.playermodel
		// console.log(item)
		this.changshoe(item,()=>{
			color&&this.setSkinColor(color,'Skin',playmodel)
		},playmodel)
	}
	changHair(item,color,model){
		let playmodel = model? model:this.playermodel
		this.changshoe(item,()=>{
			color&&this.setSkinColor(color,'Hair',playmodel)
		},playmodel)
		
	}
	// 查找元素
	findByNameMesh(name,model){
		// console.log(model)
		return new Promise(async (resolve) => {
			model.traverse(model =>{
				if(model.name.indexOf(name) != -1){
					resolve(model) 
				}
			})
		})
	}
	// 换肤色
	// draw(skin_color,imgurl){
	// 	//数组存放url
	// 	let canvas = document.createElement('canvas')
	// 	// 创建canvas 画板
	// 	let context = canvas.getContext('2d')
	// 	canvas.width = 512;
	// 	canvas.height = 512;
	// 	context.rect(0, 0, canvas.width, canvas.height)
	// 	// 画脸
	// 	let img = new Image;
	// 	img.src = imgurl;
	// 	img.crossOrigin = 'Anonymous';
	// 	// img.style
	// 	return new Promise((resolve) => {
	// 		img.onload = function() {
	// 			context.drawImage(img, 0, 0, 512, 512);
	// 			context.globalAlpha = 0.7;
	// 			context.fillStyle = skin_color;
	// 			context.fillRect(0, 0, 512, 512);
	// 		}
	// 	})
	// }
}
