import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from 'axios'

Vue.prototype.$axios = axios
Vue.config.productionTip = false
import i18n from './lang/index.js'
import "./assets/style/result.css"
window.history.pushState(null, null, window.location.href);
window.addEventListener('popstate', function () {
  window.history.forward(-1);
  window.history.pushState(null, null, window.location.href);
});
new Vue({
	i18n,
	store,
	router,
  render: h => h(App),
}).$mount('#app')
