import * as THREE from "three";
import {
	DRACOLoader
} from "three/examples/jsm/loaders/DRACOLoader";
import {
	GLTFLoader
} from "three/examples/jsm/loaders/GLTFLoader.js";
import {
	RGBELoader
} from 'three/examples/jsm/loaders/RGBELoader.js'
import {
	EXRLoader
} from 'three/examples/jsm/loaders/EXRLoader.js';
let dracoLoader
// -------------------------- 计算摄像机的角度 -------------------------

function getCameraAngle(camera) {
	if (!camera) {
		return;
	}
	const e = camera.getWorldDirection(new THREE.Vector3());
	const t = (new THREE.Vector2()).subVectors(new THREE.Vector2(e.z, e.x), new THREE.Vector2(0, -1));
	t.y = t.y - 1;
	const i = t.length();
	let r = Math.acos(t.x / i);
	t.y < 0 && (r *= -1);
	r = -(180 * r / Math.PI + 180) + 180;
	r = (r < 0 ? (r + 360) : r) % 360;
	return r;
}

// -------------------------- 计算摄像机的位置 -------------------------

function getCameraPosition(camera) {
	let res = {
		x: 0,
		y: 0,
		z: 0
	};
	if (camera && camera.position) {
		res.x = camera.position.x;
		res.y = camera.position.y;
		res.z = camera.position.z;
	}
	return res;
}

// ----------------------------计算两个二维向量的夹角-------------------------------
function Angle(to, form) {
	let x = to.x - form.x;
	let y = to.y - form.y;

	let hy = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));

	let cos = x / hy;
	let radian = Math.acos(cos);
	let angle = 180 / (Math.PI / radian);

	if (y < 0) angle = 360 - angle; // if (y < 0) angle = - angle;   //-180-180
	else if ((y == 0) && (x < 0)) angle = 180;

	return angle;
}

// 加载模型
function loadDraco(modelPath, callback) {
	let gltfLoader = new GLTFLoader();
	if (!dracoLoader) {
		dracoLoader = new DRACOLoader();
		dracoLoader.setDecoderPath("/draco/");
	}
	gltfLoader.setDRACOLoader(dracoLoader);
	return new Promise((resolve) => {
		gltfLoader.load(modelPath, (gltf) => {
			// console.log(gltf)
			callback && callback(gltf)
			resolve(gltf.scene);
		});
	});
}

function loadDracoProgress(modelPath, onLoad, onProgress) {
	let loadManager = new THREE.LoadingManager();
	loadManager.onProgress = onProgress;
	let gltfLoader_Draco = new GLTFLoader(loadManager);
	if (!dracoLoader) {
		dracoLoader = new DRACOLoader();
		dracoLoader.setDecoderPath("/draco/");
	}
	gltfLoader_Draco.setDRACOLoader(dracoLoader);
	gltfLoader_Draco.load(modelPath, onLoad);
}
// 加载hdr
function initHdr(url, renderer) {
	const loader = new RGBELoader();
	let pmremGenerator = new THREE.PMREMGenerator(renderer);
	pmremGenerator.compileEquirectangularShader();
	return new Promise((resolve) => {
		loader.load(url, texture => {
			let envMap = pmremGenerator.fromEquirectangular(texture).texture;
			resolve(envMap)
		})
	})
}

function initExr(url, renderer) {
	const loader = new EXRLoader();
	let pmremGenerator = new THREE.PMREMGenerator(renderer);
	pmremGenerator.compileEquirectangularShader();
	return new Promise((resolve) => {
		loader.load(url, texture => {
			let envMap = pmremGenerator.fromEquirectangular(texture).texture;
			resolve(envMap)
		})
	})
}
// 平面坐标转换
function getMousePosition(e, THREE, el) {
	let mouse = new THREE.Vector2();
	if (window.innerHeight > window.innerWidth) {
		mouse.x = ((e.clientY - el.offsetLeft) / el.clientWidth) * 2 - 1;
		mouse.y = -(((window.innerWidth - e.clientX) - el.offsetTop) / el.clientHeight) * 2 + 1;
	} else {
		mouse.x = ((e.clientX - el.offsetLeft) / el.clientWidth) * 2 - 1;
		mouse.y = -((e.clientY - el.offsetTop) / el.clientHeight) * 2 + 1;
	}
	return mouse
}
// 删除group，释放内存
function deleteGroup(obj) {
	// 删除掉所有的模型组内的mesh
	while (obj.children.length > 0) {
		deleteGroup(obj.children[0])
		obj.remove(obj.children[0]);
	}
	if (obj.geometry) obj.geometry.dispose()
	if (obj.material) {
		Object.keys(obj.material).forEach(prop => {
			if (!obj.material[prop])
				return
			if (typeof obj.material[prop].dispose === 'function')
				obj.material[prop].dispose()
		})
		obj.material.dispose()
	}
}
// 随机数
function randomnum(num,range){
	let Max = num+range
	let Min = num -range
	let Range = Max - Min;
	let Rand = Math.random();
	let newnum = Min + Rand * Range; //四舍五入
	return newnum;
}

export {
	getCameraAngle,
	getCameraPosition,
	Angle,
	loadDraco,
	initHdr,
	initExr,
	loadDracoProgress,
	deleteGroup,
	getMousePosition,
	randomnum
};
