import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from './en'
import zhLocale from './cn'

Vue.use(VueI18n)

const messages = {
  en:enLocale,
  cn:zhLocale
}
let defaultlang = window.weburl ? 'cn' : 'en'

const i18n = new VueI18n({
    locale: localStorage.getItem('lang')|| defaultlang,
    silentFallbackWarn: true,  // 设置为true后，在组件内使用时在浏览器不会报警告
    messages
})

export default i18n
