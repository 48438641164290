// import store from '@/store'
// console.log(store)
let pinus = window.pinus;
console.log(pinus)
// pinus.disconnect((data)=>{
// 	console.log(data)
// })
// let host = "wss://nodeserver.argamemap.com";
let host = "wss://nodeserver-arx.argamemap.com"
if(window.weburl){
	host =  "wss://nodeserver.argamemap.com";
}
let port = "3014"; //3014
let rid = "world" //大房间
let username =sessionStorage.getItem('username');//当前用户名
let useruid = sessionStorage.getItem('oneidname');
let timeconnection;
let animation = 'Animation_Idle'
// uid 唯一的身份信息
// connector ->房间加人物名作为uid -->连接到对应的host port
// 通过chat 后端服务器，进行广播，这时候已经t'plus.geolocation.
// 请求gate (当前用户唯一的身份信息)

function initpinus(host,port,callback){
	pinus.init({
		host: host,
		port: port,
		secure: true,
	}, function() {
		callback()
	});
}
// 建立联系 查询当前用户是否在线
function getuser(obj, callback) {
	initpinus(host,3010,()=>{
		let route = 'connector.entryHandler.getuser';
		pinus.request(route, obj, function(data) {
			if(data.code === 500) {
				callback(0)
			}else{
				callback(1,data)
				pinus.disconnect()
			}
			
		});
	})
};
// 建立联系负载均衡，会返回 新端口
function getgate(obj, callback) {
	initpinus(host,port,()=>{
		let route = 'gate.gateHandler.queryEntry';
		pinus.request(route, obj, function(data) {
			if(data.code === 500) {
				callback(0)
			}else{
				callback(1,data)
			}
		});
	})
};
// 连接 
function getconnector(data,obj,callback) {
	pinus.init({
		host: host,
		port: data.port,
		secure: true,
	}, function() {
		var route = "connector.entryHandler.enter";
		pinus.request(route, obj, (data)=> {
			time()
			callback(data)
			if(data.error) {
				console.error(data.error);
				return;
			}
		});
	});
}

// 修改当前用户动画
function changeAnimation(Animation,callback) { 
	useruid = sessionStorage.getItem('oneidname');
	animation =Animation;
	let route = "chat.chatHandler.changeAnimation";
	if(useruid&&Animation){
		pinus.request(route,{
			useruid,
			Animation
		}, function(data) {
			callback()
		});
	}
}
// 	修改当前转向信息
function changeAngle(Angle,callback) { 
	if(useruid&&Angle){
		let route = "chat.chatHandler.changeAngle";
		pinus.request(route,{
			useruid,
			Angle
		}, function(data) {
			callback()
		});
	}
}
// 修改位置信息
function changePosition(arr,callback) { 
	
	if(useruid&&arr){
		let route = "chat.chatHandler.changePosition";
		pinus.request(route,{
			useruid,
			arr
		}, function(data) {
			callback()
		});
	}
}
// 切换场景
function changePlace(obj,callback){
	useruid = sessionStorage.getItem('oneidname');
	if(useruid&&obj){
		let route = "connector.entryHandler.changePlace";
		pinus.request(route,obj, function(data) {
			callback(data)
		});
	}
}
// 修改虚拟人物形象
function changeAvatar(callback) { 
	useruid = sessionStorage.getItem('oneidname');
	if(useruid){
		let route = "chat.chatHandler.changeAvatar";
		pinus.request(route,{
			useruid
		}, function(data) {
			callback()
		});
	}
}
// 修改ppt状态
function changeppt(obj,callback){
	let route = "chat.chatHandler.uploadppt";
	pinus.request(route,obj, function(data) {
		// console.log(data)
		callback()
	});
}
// 修改ppt地址
function changeppturl(obj,callback){
	let route = "chat.chatHandler.uploadppturl";
	pinus.request(route,obj, function(data) {
		// console.log(data)
		callback()
	});
}
// 获取当前ppt地址
function getpptlist(callback){
	// let route = "connector.entryHandler.getpptlist";
	let route = "chat.chatHandler.getpptlist";
	pinus.request(route,{}, function(data) {
		callback(data)
	});
}


function changechair(addchair,chairname){
	useruid = sessionStorage.getItem('oneidname');
	let route = "chat.chatHandler.changechair";
	pinus.request(route,{
		useruid,
		addchair,
		chairname
	}, function(data) {
		// callback()
	});
}

// 把人加入会话频道
function adduserchannel(useruid,dialogueChannelName,publicdialog,callback){
	console.log('人加入会话频道',useruid,dialogueChannelName,publicdialog,callback)
	var route = "connector.entryHandler.adddialoguechannel";
	// console.log('把人加入会话频道0',{
	// 	useruid,
	// 	dialogueChannelName,
	// 	publicdialog
	// })
	pinus.request(route,{
		useruid,
		dialogueChannelName,
		publicdialog
	}, function(data) {
		callback&&callback()
		// console.log(data)
	});
}
function senddialoguechannel(param,callback){
	var route = "chat.chatHandler.senddialoguechannel";
	pinus.request(route,param, function(data) {
		// console.log(data)
		callback&&callback()
	});
}
function changeEmtoe(Animation){
	useruid = sessionStorage.getItem('oneidname');
	var route = "chat.chatHandler.changeEmtoe";
	pinus.request(route,{
		useruid,
		Animation
	}, function(data) {
		// callback&&callback()
	});
} 
// 隔一段时间，将当前信息发送出去，如果发送不出去就说明pinus断开了
function time(){
	var period = 5000; // 1 second
	clearInterval(timeconnection)
	timeconnection = setInterval(()=> {
		try{
			pinus.data&&changeAnimation(animation,(data)=>{})
		}catch(e){
			if(e){
				var r=confirm("err,Please refresh");
				location.reload();
			}
		}
	}, period);
}


export {
	getpptlist,
	changeppturl,
	changeppt,
	getgate,
	getconnector,
	changeAnimation,
	changeAngle,
	changePosition,
	changeAvatar,
	changePlace,
	getuser,
	changechair,
	adduserchannel,
	senddialoguechannel,
	changeEmtoe
}