<!-- 在输入法的上面添加一个输入框 -->
<template>
	<div class="inputKeyBoard">
		<div class="input_all">
			<div class="input" ref="rightBody">{{content}}</div>
			<SimpleKeyboard :keyboardClass="keyboardClass" @onKeyPress="onKeyPress"  @onChangeKeyboard="onChangeKeyboard"  :input="content"/>
		</div>
		
	</div>
</template>

<script>
	import SimpleKeyboard from '@/components/SimpleKeyboard/SimpleKeyboard.vue'
	export default{
		data(){
			return{
				content:''
			}
		},
		props: {
			keyboardClass: {
				default: 'simple-keyboard',
				type: String,
			},
			initcontent:{
				default: '',
				type: String,
			}
		},
		components:{
			SimpleKeyboard
		},
		mounted() {
			this.content = this.initcontent
		},
		methods:{
			onChangeKeyboard(e){
				this.content = e;
				this.$nextTick(() => {
					this.$refs.rightBody.scrollLeft = this.$refs.rightBody.scrollWidth
				})
			},
			onKeyPress(e){
				if(e == '{enter}'){
					this.$emit('enter',this.content)
				}else if(e == '{close}'){
					this.$emit('changeshowboardClass',false)
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.inputKeyBoard{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.6);
		.input_all{
			width: 100%;
			position: absolute;
			bottom: 0px;
			display: flex;
			align-items: center;
			flex-direction: column;
			.input{
				white-space: nowrap;
				width: 60%;
				border-radius: 5px 5px  5px 5px;
				color: black;
				background-color: #fff;
				overflow-y:hidden;
				overflow-x: auto;
				display: flex;
				align-items: center;
				height: 40px;
				padding: 9px;
				box-sizing: border-box;
			}
		}
	}
</style>