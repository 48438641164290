import * as THREE from "three";
import store from '@/store'
import  currentPlayer from './initplayer.js'
import {
	changeAnimation,
	changeAngle,
	changePosition,
	changePlace,
	getgate,
	getconnector,
	changechair,
	changeEmtoe,
	} from '@/request/pinus'
import {
	getvisitoption
} from '@/request/api/visit.js'
import {
	loadDraco,
} from "@/util/compass.js"
let touristPlayer = [
	{
		name: 'Andy.Hu',
		playerurl: 'https://qiniu.argamemap.com/arweb-virtualshop/model/player_model/0302/gltf_1/1/OUT.gltf',
		mixer: null,
		clips: null,
		positionmark: new THREE.Vector3(-3, 0.1, 12.8),
		positionblock: new THREE.Vector3(5.2, 0.2, -3),
	},
	{
		name: 'Mikayla',
		playerurl: 'https://qiniu.argamemap.com/arweb-virtualshop/model/player_model/0302/gltf_1/3/OUT.gltf',
		mixer: null,
		clips: null,
		positionmark: new THREE.Vector3(2, 0.1, 32),
		positionblock: new THREE.Vector3(-9, 0.2, -8),
	},
	{
		name: 'Graham',
		playerurl: 'https://qiniu.argamemap.com/arweb-virtualshop/model/player_model/0302/gltf_1/4/OUT.gltf',
		mixer: null,
		clips: null,
		positionmark: new THREE.Vector3(-2, 0.1, 32),
		positionblock: new THREE.Vector3(1, 0.2, 6.2),
	},
	{
		name: 'Angus',
		playerurl: 'https://qiniu.argamemap.com/arweb-virtualshop/model/player_model/0302/gltf_1/5/OUT.gltf',
		mixer: null,
		clips: null,
		positionmark: new THREE.Vector3(11.7, 0.1, 9.7),
		positionblock: new THREE.Vector3(2.2, 0.2, -13),
	},
]
let pinus =  window.pinus
let clock = new THREE.Clock();
// 获取当前场景人物数量和位置
class createPlayer {
	constructor() {
		this.player = null;//主控人物
		this.mixer=null;//主控人物
		this.clips=null;//主控人物的关键帧
		this.newaction = null;//主控人物的对应动画
		this.actionstats = null;//判断当前状态，避免一直赋值
		this.mixerarr=[];//所有的动画
		this.anyonelist={};
		this.angle =0;//主控人物转向反向
		this.angleflag =clock.getElapsedTime();//限流
		this.positionflag = clock.getElapsedTime();//限流
		this.playergroup =new THREE.Group();
		this.initplayerfn =new currentPlayer()
		this.Firstlogin = true; //是否是第一次加载
		this.place = null;//当前场景的信息
		// this.pinusflag = false;
		this.playerAuidoicon={};
		// this.chairlocation={};//椅子数组
		this.pinusconnect = false;
		this.newplayerchair =null; //当前座位
		this.chairArr = {}; //当前有人的座位
		this.newactionname =false;
		this.OtherUserList ={
			arr:[],
			users:{},
		};
	}
	// 初始化信息
	init(place){
		this.place = place
		// 初始化其他人物信息
		this.connectorUser(place)
	}
	// 链接pinus
	async connectorUser(initscenename){
		let modelurlid = sessionStorage.getItem('Virtualpalyer')?JSON.parse(sessionStorage.getItem('Virtualpalyer')).modelurlid:1
		let obj ={
			useruid:sessionStorage.getItem('oneidname'),
			nickname:sessionStorage.getItem('username'),
			Avatarid:modelurlid,
			// Avatar:sessionStorage.getItem('Virtualpalyer'),
			...initscenename,
		}
		if(this.Firstlogin){
			let result = await this.loginConnection(obj)
			this.uploadPlayer()
			this.Firstlogin = false
			this.OtherUserList =  result
			this.loadanyonePlayer()
		}else{
			this.OtherUserList = await  this.changeanyoneplace(obj)
			this.loadanyonePlayer()
			this.pinusconnect = true
		}
	}
	// 第一次发出的连接
	loginConnection(obj){
		return new Promise((resolve) => {
			getgate({useruid:sessionStorage.getItem('oneidname')},(result,data)=>{
				if(result){
					getconnector(data,obj,(data)=>{
						if(data.code == 200){
							// console.log('connector',data)
							let arr = data.users.arr?data.users.arr:[]
							let newuserlist = {}
							for(let i=0 ;i < arr.length;i++){
								if(arr[i]){
									newuserlist[arr[i].useruid] = arr[i]
								}
								if(arr[i].chairname){
									this.chairArr[arr[i].chairname] = arr[i].useruid;
								}
							}
							let obj = {
								code:data.code ,
								arr: newuserlist,
								users:data.users.users,
							}
							this.pinusconnect = true
							
							resolve(obj)
						}
					})
					
				}else{
					console.log('pinus连接失败')
				}
			})
		});
	}
	// 切换人物房间
	changeanyoneplace(obj){
		return new Promise((resolve) => {
			changePlace(obj,(data)=>{
				// console.log('connector',data)
				for(let item in data.arr){
					if(data.arr[item].chairname){
						this.chairArr[data.arr[item].chairname] = item;
					}
				}
				// console.log(this.chairArr)
				resolve(data)
			})
		});
	}	
	// 加载静态游客模型 // playerurl--地址 name--游客名字 position--所在位置
	loadmodel(playerurl,name,position){
		return new Promise((resolve) => {
			loadDraco(playerurl,async (mesh) => {
				let model =mesh.scene 
				model.scale.set(0.8,0.8,0.8)
				let font = await this.add3DFont(name)
				font.position.set(model.position.x,model.position.y+2,model.position.z)
				model.add(font);
				model.position.x= position.x
				model.position.y= position.y
				model.position.z= position.z
				let mixer = new THREE.AnimationMixer(model);
				this.mixerarr.push(mixer)
				let clips = mesh.animations;
				var clip = THREE.AnimationClip.findByName( clips, 'Idle' );
				let newaction = mixer.clipAction( clip );
				newaction.play();
				resolve(model)
			})
		});
	}
	// 加载文字
	add3DFont(font){
	        //先用画布将文字画出
	        let canvas = document.createElement("canvas");
	        let ctx = canvas.getContext("2d");
			let width =120;
			let cornerRadius=20;
			let x =90;
			let y=45;
			let height = 45;
			ctx.fillStyle = 'rgba(108, 108, 108, 0.5)'
			ctx.beginPath();
			ctx.moveTo(x + cornerRadius, y);
			ctx.arcTo(x + width, y, x + width, y + height, cornerRadius);
			ctx.arcTo(x + width, y + height, x, y + height, cornerRadius);
			ctx.arcTo(x, y + height, x, y, cornerRadius);
			ctx.arcTo(x, y, x + width, y, cornerRadius);
			ctx.closePath();
			ctx.fill();
			ctx.fillStyle = "#ffffff";
			ctx.font = "Bold 20px Arial";
			ctx.textAlign = 'center'
			ctx.fillText(font,150,75);
			
	        let texture = new THREE.Texture(canvas);
	        texture.needsUpdate = true;
			texture.encoding = THREE.sRGBEncoding;
	        //使用Sprite显示文字
	        let material = new THREE.SpriteMaterial({map:texture,depthWrite:false});
	        let textObj = new THREE.Sprite(material);
			let size = 1;
	        textObj.scale.set(1.4 * size, 0.7 * size, 0.7 * size);
			// textObj.scale.set(8 * size, 8 * size, 8 * size);
	        return textObj;
	}
	 // 加载主控人物
	loadplayermodel() {
		return new Promise((resolve) => {
			this.initplayerfn.initallplayer(sessionStorage.getItem('Virtualpalyer'), async (model1,mesh)=>{
				// console.log(mesh)
				let model =mesh.scene
				model.scale.set(0.8,0.8,0.8)
				this.mixer = new THREE.AnimationMixer(model);
				this.clips = mesh.animations;
				let font = await this.add3DFont(store.state.user.name);
				model.add(font);
				font.position.set(0,model.position.y+2,0);
				var bbox = new THREE.Box3();
				bbox.setFromObject(model); //获取包围盒
				let pointMesh;
				if(!this.pointMat){
					this.pointGeo = new THREE.PlaneGeometry(1, 1);
					this.pointMat = new THREE.MeshStandardMaterial({
						map: new THREE.TextureLoader().load("/img/shadow.png"),
						transparent: true,
						opacity:0.5,
						// depthTest:false
					})
				}
				pointMesh = new THREE.Mesh(this.pointGeo, this.pointMat);
				pointMesh.position.set(0,0.01,0)
				pointMesh.renderOrder = 9;
				pointMesh.rotateX(-Math.PI / 2);
				pointMesh.scale.set(1,1,1)
				model.add(pointMesh);
				model.capsuleInfo = {
					radius: 0.3,
					segment: new THREE.Line3(new THREE.Vector3(0,0,0), new THREE.Vector3(0, 1, 0))
					// segment: new THREE.Line3(new THREE.Vector3(0,0,0), bbox.max)
				};
				let playicon ;
				if(!this.playicon){
				    playicon  =	this.loadplayingicon()
					
				}else{
					playicon = this.playicon.clone()
				}
				playicon.name = store.state.user.oneidname;
				playicon.scale.set(0.3,0.3,0.3)
				model.add(playicon);
				this.playerAuidoicon[store.state.user.oneidname+'audio'] = playicon
				playicon.visible = false
				playicon.position.set(0.4,model.position.y+2.05,0);
				this.player =model
				window.player = this.player ;
					// model= new THREE.Group();
				resolve(model)
			})
		});
	}
	//加载正在播放的图标
	loadplayingicon(){
		let texture = new THREE.TextureLoader().load("/img/nowplay.png");
		let material = new THREE.SpriteMaterial({map:texture,depthWrite:false});
		this.playicon= new THREE.Sprite(material);
		return  this.playicon
	}
	// 加载感叹号
	loaduserinfoicon(name){
		let texture = new THREE.TextureLoader().load("/img/exclamation_point.png");
		let material = new THREE.SpriteMaterial({map:texture,depthWrite:false});
		this.userinfo= new THREE.Sprite(material);
		return  this.userinfo
	}
	// 声音发生改变（正在说话图标）
	changeplayingArr(newMessage){
		for(let key in this.playerAuidoicon){
			if(newMessage.includes(key)){
				this.playerAuidoicon[key].visible = true
			}else{
				this.playerAuidoicon[key].visible = false
			}
		}
	}
	//加载静态
	loadallmodel(){
		let playergroup = new THREE.Group();
		return playergroup
	}
	// 加载其他人物
	loadanyonePlayermodel(playerinfo) {
		return new Promise((resolve) => {
			this.initplayerfn.initallplayer(playerinfo.Avatar, async (model1,mesh)=>{
				let model =mesh.scene
				model.scale.set(0.8,0.8,0.8)
				let mixer = new THREE.AnimationMixer(model);
				let clips = mesh.animations;
				let font = await this.add3DFont(playerinfo.nickname);
				font.position.set(0,model.position.y+2,0);
				
				let playicon ;
				if(!this.playicon){
				    playicon  =	this.loadplayingicon()
					
				}else{
					playicon = this.playicon.clone()
				}
				let userinfoicon;
				if(!this.userinfo){
					userinfoicon = this.loaduserinfoicon()
				}else{
					userinfoicon = this.userinfo.clone()
				}
				userinfoicon.useruid = playerinfo.useruid;
				userinfoicon.visible = true
				userinfoicon.scale.set(0.25,0.25,0.25)
				userinfoicon.position.set(-0.4,model.position.y+2.05,0);
				userinfoicon.name = 'userinfo'
				model.add(userinfoicon);
				// console.log(userinfoicon)
				playicon.name =playerinfo.useruid;
				playicon.scale.set(0.3,0.3,0.3)
				model.add(playicon);
				this.playerAuidoicon[playerinfo.useruid+'audio'] = playicon
				playicon.visible = false
				playicon.position.set(0.4,model.position.y+2.05,0);
				
				// model.rotation.y = playerinfo.angle
				model.rotation.y = playerinfo.angle.rotate;
				const quaternion = new THREE.Quaternion(playerinfo.angle.quaternion[0],playerinfo.angle.quaternion[1],playerinfo.angle.quaternion[2],playerinfo.angle.quaternion[3]);
				model.setRotationFromQuaternion(quaternion)
				model.add(font);
				model.position.set(playerinfo.position[0],playerinfo.position[1],playerinfo.position[2]);
				let pointMesh;
				if(!this.pointMat){
					this.pointGeo = new THREE.PlaneGeometry(1, 1);
					this.pointMat = new THREE.MeshStandardMaterial({
						map: new THREE.TextureLoader().load("/img/shadow.png"),
						transparent: true,
						opacity:0.5,
						// depthTest:false
					})
				}
				pointMesh = new THREE.Mesh(this.pointGeo, this.pointMat);
				// console.log(pointMesh)
				pointMesh.position.set(0,0.008,0)
				pointMesh.renderOrder = 9;
				pointMesh.rotateX(-Math.PI / 2);
				pointMesh.scale.set(1,1,1)
				model.add(pointMesh);
				
				resolve({
					model,
					mixer,
					clips
				})
			})
		});
	}
	// 初始人物加载多人在线 进入游戏的时候拿到了所有的当前人物加载到页面中
	async loadanyonePlayer(){
		let playerarr = this.OtherUserList.arr;
		let useruidarr =this.OtherUserList.users;
		//console.log('现在的人',this.OtherUserList)
		store.commit('user/changeuserlist',playerarr)
		if(useruidarr&&useruidarr.length >0){
			// 虚拟人arr
			let dataAvatar = await getvisitoption({userid:[useruidarr]})
			if(dataAvatar.code){
				for(let i=0;i<dataAvatar.data.length;i++){
					playerarr[dataAvatar.data[i].userid].Avatar =JSON.stringify( dataAvatar.data[i])
				}
			}else{
				for(let i=0;i<useruidarr.length;i++){
					let dataAvatar = await getvisitoption({userid:[useruidarr[i]]})
					if(dataAvatar.code != 0){
						playerarr[useruidarr[i]].Avatar =JSON.stringify(dataAvatar.data[0])
					}
				}
			}
		}
		let newplayerarr ={}
		for (let key in playerarr) {
			// 当前地区的人 不包括自己
			if( playerarr[key].useruid != sessionStorage.getItem('oneidname') &&playerarr[key].Avatar){
				let player1;
				try{
					player1 = await this.loadanyonePlayermodel(playerarr[key])
					newplayerarr[key] ={
						...playerarr[key],
						...player1
					}
					this.changeanyoneAnimation(newplayerarr[key])
					this.playergroup.add(player1.model);
				}catch(e){
					//TODO handle the exception
				}
			}
		}
		this.anyonelist=newplayerarr	
		return this.playergroup
	}
	// 控制其他人物动画
	changeanyoneAnimation(playerinfo){
		playerinfo.newaction &&playerinfo.newaction.stop()
		var clip = THREE.AnimationClip.findByName( playerinfo.clips, playerinfo.Animation);
		let newaction = playerinfo.mixer.clipAction( clip );
			
		if( playerinfo.Animation == 'Animation_SitDown'){
			newaction.loop =THREE.LoopOnce
			newaction.clampWhenFinished = true;
		}
		playerinfo.newaction = newaction
		newaction.play();
	}
	// 有新增加的人物
	addanyonePlayer(data){
		getvisitoption({userid:[data.useruid]}).then(async dataAvatar=>{
			data.Avatar =JSON.stringify(dataAvatar.data[0])
			let player1 = await this.loadanyonePlayermodel(data)
			this.playergroup.add(player1.model);
			this.anyonelist[data.useruid]={
						...data,
						...player1
					}
			if(this.chairArr[data.chairname]){
				this.chairArr[data.chairname]= data.useruid;
			}
			this.changeanyoneAnimation(this.anyonelist[data.useruid])
		})
		
	}
	// 有用户离开拿到对应人物移除
	async LeaveanyonePlayer(data){
		// console.log(data)
		if(this.chairArr[data.chairname]){
			this.chairArr[data.chairname]= data.useruid;
		}
		// console.log(this.chairArr)
		this.anyonelist[data.useruid]&&this.playergroup.remove(this.anyonelist[data.useruid].model)
		delete this.anyonelist[data.useruid]
	}
	// 改变用户转向信息
	changeplayerAngle(data){
		// console.log(data)
		// this.anyonelist[data.uid].model.rotation.y = data.Angle
		this.anyonelist[data.uid].model.rotation.y = data.Angle.rotate;
		const quaternion = new THREE.Quaternion(data.Angle.quaternion[0],data.Angle.quaternion[1],data.Angle.quaternion[2],data.Angle.quaternion[3]);
		this.anyonelist[data.uid].model.setRotationFromQuaternion(quaternion)
	}
	// 改变用户信息
	changeplayerPosition(data){
		this.anyonelist[data.uid].model.position.x = data.Position[0]
		this.anyonelist[data.uid].model.position.y = data.Position[1]
		this.anyonelist[data.uid].model.position.z = data.Position[2]
	}
	// 改变当前转向
	changeAnglePlayer(){
		if(!this.pinusconnect) return
		let newtime = clock.getElapsedTime();
		if(newtime - this.angleflag >0.3 &&this.angle != this.player.rotation.y ){
			// let angle = this.player.rotation.y
			let angle={
				rotate:this.player.rotation.y,
				quaternion:[this.player.quaternion.x,this.player.quaternion.y,this.player.quaternion.z,this.player.quaternion.w],
			}
			pinus.data&&changeAngle(angle,()=>{
				this.angleflag = clock.getElapsedTime();
			})
		}
		
	}
	// 改变用户位置
	changePositionPlayer(){
		if(!this.pinusconnect) return
		let newtime = clock.getElapsedTime();
		if(newtime - this.positionflag >0.01){
			let position = this.player.position
			// console.log(position)
			let arr =[position.x,position.y,position.z]
			pinus.data&&changePosition(arr,()=>{
				this.positionflag = clock.getElapsedTime();
			})
		}
	}
	sitdown(newplayerchair){
		// if(	this.actionstats == 'Animation_SitDown') return
		if(store.state.user.select_Animation == 'Animation_SitDown') return
		var clip = THREE.AnimationClip.findByName( this.clips, 'Animation_SitDown' );
		this.newaction &&this.newaction.stop()
		this.newaction = this.mixer.clipAction( clip );
		this.newaction.loop =THREE.LoopOnce
		this.newaction.clampWhenFinished = true;
		this.newaction.play();
		// this.actionstats = 'Animation_SitDown'
		store.commit('user/changeselect_Animation','Animation_SitDown')
		if(!this.pinusconnect) return
		pinus.data&&changeAnimation('Animation_SitDown',()=>{})
		pinus.data&&changechair(true,newplayerchair)
		
	}
	changeanimationaction(Animationname,clampWhenFinished,statas){
		// if(	this.actionstats == Animationname) return
		if(store.state.user.select_Animation == Animationname) return
		// this.newactionname = Animationname
		// console.log(2222,Animationname,store.state.user.select_Animation )
		
		this.newaction &&this.newaction.stop()
		var clip = THREE.AnimationClip.findByName( this.clips, Animationname );
		this.newaction = this.mixer.clipAction( clip );
		this.newaction.play();
		// this.actionstats = Animationname
		// 是否要禁止其他动画播放（碰撞）
		this.newactionname = statas
		// 是否要保留最后一帧
		this.newaction.clampWhenFinished = clampWhenFinished;
		// 当前动作
		store.commit('user/changeselect_Animation',Animationname)
		if(!this.pinusconnect) return
		pinus.data&&changeAnimation(Animationname,()=>{})
	}
	// 控制主控人物
	walk(){
		this.changeAnglePlayer()
		this.changePositionPlayer()
		this.changeanimationaction('Animation',false)
	}
	stop(){
		this.changeanimationaction('Animation_Idle',true)
	}
	Jump(){
		this.changeAnglePlayer()
		this.changePositionPlayer()
		if(store.state.user.select_Animation == 'Animation_Jump') return
		// if(	this.actionstats == 'Animation_Jump') return
		this.newaction &&this.newaction.stop()
		var clip = THREE.AnimationClip.findByName( this.clips, 'Animation_Jump' );
		this.newaction = this.mixer.clipAction( clip );
		this.newaction.loop =THREE.LoopOnce 
		this.newaction.clampWhenFinished = true;
		this.newaction.play();
		store.commit('user/changeselect_Animation','Animation_Jump')
		// this.actionstats = 'Animation_Jump'
		if(!this.pinusconnect) return
		pinus.data&&changeAnimation('Animation_Jump',()=>{})
	}
	running(){
		this.changeAnglePlayer()
		this.changePositionPlayer()
		this.changeanimationaction('Animation_Run',false)
	}
	// 切换动作
	changeplayerActionArr(item){
		if(store.state.user.select_Animation == item.Animation){
			this.clearaction()
		}else{
			this.changeanimationaction(item.Animation,true,true)
		}
	}
	// 切换表情
	changeplayerEmtoeArr(item,icon){
		if(store.state.user.select_Emtoe == icon){
			this.player.remove(this.playiconEmtoe);
			store.commit('user/changechangeplayerEmtoeArr',null)
			changeEmtoe(false)
		}else{
			this.playiconEmtoe&&this.player.remove(this.playiconEmtoe);
			let texture = new THREE.TextureLoader().load(item);
			let material = new THREE.SpriteMaterial({map:texture,depthWrite:false});
			let playicon = new THREE.Sprite(material);
			playicon.scale.set(0.3,0.3,0.3)
			console.log(this.player.position.y)
			playicon.position.set(0,2.3,0);
			this.playiconEmtoe = playicon
			this.playiconEmtoe.name = item.name
			this.player.add(this.playiconEmtoe);
			store.commit('user/changechangeplayerEmtoeArr',icon)
			changeEmtoe(item)
		}
	}
	// 别人的表情
	changeanyonelistEmtoeArr(item){
		// console.log(this.anyonelist[item.uid].iconEmtoe,this.anyonelist[item.uid])
		this.anyonelist[item.uid].iconEmtoe&&this.anyonelist[item.uid].model.remove(this.anyonelist[item.uid].iconEmtoe)
		if(item.Animation){
			let texture = new THREE.TextureLoader().load(item.Animation);
			let material = new THREE.SpriteMaterial({map:texture,depthWrite:false});
			let playicon = new THREE.Sprite(material);
			playicon.scale.set(0.3,0.3,0.3)
			playicon.position.set(0,this.player.position.y+2.3,0);
			this.anyonelist[item.uid].iconEmtoe = playicon;
			this.anyonelist[item.uid].model.add(playicon)
		}
		// console.log(this.anyonelist)
	}
	uploadPlayer(){
		pinus.on('onAddstarbox', (data)=> {
			// console.log('onAddstarbox',data,this.anyonelist,this.anyonelist[data.useruid])
			if(this.anyonelist&& !this.anyonelist[data.useruid]&& data.useruid != sessionStorage.getItem('oneidname')){
				this.addanyonePlayer(data)
			}
		});
		pinus.on('onLeave', (data)=> {
			// console.log('onLeave',data)
			this.LeaveanyonePlayer(data)
		});
		pinus.on('onchangeAnimation', (data)=> {
			// console.log(data)
			if( this.anyonelist&&this.anyonelist[data.uid]&&data.uid != sessionStorage.getItem('oneidname')){
				this.anyonelist[data.uid].Animation = data.Animation
				this.changeanyoneAnimation(this.anyonelist[data.uid])
			}
			
		});
		pinus.on('onchangeAngle', (data)=> {
			// console.log('onchangeAngle',data)
			if( this.anyonelist&&this.anyonelist[data.uid]&&data.uid != sessionStorage.getItem('oneidname')){
				this.changeplayerAngle(data)
			}
			
		});
		pinus.on('onchangePosition', (data)=> {
			// console.log('onchangePosition',data)
			if(this.anyonelist&&this.anyonelist[data.uid]&&data.uid != sessionStorage.getItem('oneidname')){
				this.changeplayerPosition(data)
			}
			
		});
		pinus.on('onchangeEmtoe', (data)=> {
			// console.log('onchangeEmtoe',data,this.anyonelist)
			if(this.anyonelist&&this.anyonelist[data.uid]&&data.uid != sessionStorage.getItem('oneidname')){
				this.changeanyonelistEmtoeArr(data)
			}
			
		});
		pinus.on('onchangechair',(data)=>{
			// console.log(data)
			// this.OtherUserList.haveuserchair = data.chairarr
			if(data.addchair){
				this.chairArr[data.chairname] = data.uid
			}else{
				delete this.chairArr[data.chairname]
			}
		})
	}
	clearaction(){
		if(this.newactionname){
			// 恢复碰撞
			this.newactionname = false
			store.commit('user/changeselect_Animation',null)
		}
		
	}
	update(delta){
		 this.mixer.update(delta );
		 this.mixerarr.forEach(item=>{
			 item.update(delta );
		 })
		 for (let key in this.anyonelist) {
			 this.anyonelist[key].mixer.update(delta);
		 }
	}
	
	// 切换页面时销毁
	deletemixer(){
		this.mixerarr=[];
	}
}
export default new createPlayer()
