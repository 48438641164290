const VirtualDressing ={
    namespaced:true,
    state:{
		loadflag:false,
		load_percent:{
			flag:false,
			allnum:1,
			newnum:0
		},
		option:{
			player:null,
			Coat:null,
			Trousers:null,
			Eyes:null,
			Skin:null,
			Face:null,
			Hair:null,
			haircolor:null,
			Brows:null,
			Shoe:null,
		},
    },
    mutations:{
        changeselectarr(state,obj){
			state.option[obj.name] = obj.selectitem
		},
		changestate(state,obj){
			state.option = obj
			// sessionStorage.setItem('Virtualpalyer1',JSON.stringify(state) )
		},
		changeloadflag(state,obj){
			state.loadflag = obj
			if(!obj){
				state.load_percent.flag = false;
			}
		},
		changeload_percent_flag(state,obj){
			state.load_percent.flag = obj
		},
		changeload_percent_allnum(state,obj){
			state.load_percent.allnum = 1
			state.load_percent.allnum = obj
		},
		changeload_percent_newnum(state,obj){
			state.load_percent.newnum = 0
			state.load_percent.newnum = obj
		},
    }
}
export default VirtualDressing;