<template>
	<div class="login_all">
		<img class="banner">
		<div class="shoawd"></div>
		<div class="from">
			<form class="login-from" :style="{display:state=='登录'?'block':'none'}">
				<div class="title">
					<div class="logo">
						<img src="/img/logo.png" alt="">
					</div>
					<div class="name">{{$t('login.title')}}</div>
				</div>
				<input type="text" v-if="computer" v-model="phone" placeholder="Username">
				<input type="text" v-else @click="changevalueinput('phone')" v-model="phone" placeholder="Username"
					readonly>
				<input type="password" v-if="computer" v-model="password" placeholder="Password">
				<input type="password" v-else @click="changevalueinput('password')" v-model="password"
					placeholder="Password" readonly>
				<div class="tip">{{tip}}</div>
				<div class="btn" v-if="canlogin" @click="gologin">{{$t('login.login')}}</div>
				<div class="nonetn"  v-else> {{$t('login.login')}}</div>
				<div class="hint">
					<div @click="Visitorlogin()">
						{{$t('login.Visitorlogin')}}
					</div>
					<div @click="setstate('注册')">
						{{$t('login.Register')}}
					</div>
				</div>
			</form>
			<form class="login-from" :style="{display:state=='注册'?'block':'none'}">
				<div class="title">
					<div class="logo">
						<img src="/img/logo.png" alt="">
					</div>
					<div style="font-size: 20px; margin-left: 10px;">{{$t('login.Register')}}</div>
				</div>
				<div class="input_centent">
					<div class="input_left">
						<input type="text" v-if="computer" v-model="phone" placeholder="Username">
						<input type="text" v-else @click="changevalueinput('phone')" v-model="phone" placeholder="Username"
							readonly>
						<input type="password" v-if="computer" v-model="password" placeholder="Password">
						<input type="password" v-else @click="changevalueinput('password')" v-model="password"
							placeholder="Password" readonly>
<!-- 						<input type="password" v-if="computer" v-model="againPass" placeholder="confirm password">
						<input type="password" v-else @click="changevalueinput('againPass')" v-model="againPass"
							placeholder="confirm password" readonly /> -->
					</div>
					<div class="input_right">
						<input type="text" v-if="computer" v-model="telephone" placeholder="Telephone(choosable)">
						<input type="text" v-else @click="changevalueinput('telephone')" v-model="telephone"
							placeholder="telephone(choosable)" readonly />
						<input type="text" v-if="computer" v-model="email" placeholder="Email(choosable)">
						<input type="text" v-else @click="changevalueinput('email')" v-model="email"
							placeholder="email(choosable)" readonly />
					</div>
					<div class="inputname">
						<input type="text" v-if="computer" v-model="familyname" placeholder="Family Name">
						<input type="text" v-else @click="changevalueinput('familyname')" v-model="familyname"
							placeholder="familyname" readonly />
						<input type="text" v-if="computer" v-model="name" placeholder="Name">
						<input type="text" v-else @click="changevalueinput('name')" v-model="name"
							placeholder="name" readonly />
					</div>
				</div>
				
				<div class="tip">{{tip}}</div>
				<div class="btn" @click="goregister">{{$t('login.Register')}}</div>
				<div class="hint">
					<div @click="Visitorlogin()">
						{{$t('login.Visitorlogin')}}
					</div>
					<div @click="setstate('登录')">
						{{$t('login.tip')}}
					</div>
				</div>
			</form>
		</div>
		<InputKeyBoard  :initcontent="initcontent"  v-if="showboardClass" @enter="enter" @changeshowboardClass='changeshowboardClass'>
		</InputKeyBoard>
	</div>

</template>

<script>
	import InputKeyBoard from '@/components/SimpleKeyboard/InputKeyBoard.vue'
	import {
		mapState
	} from 'vuex'
	// 导入接口
	import {
		loginiInterface,
		registrationiInterface,
		// setvisit,
		getvisit,
		getvisitoption
	} from '@/request/api/visit.js'
	import {
		getuser
	} from '@/request/pinus'

	export default {
		data() {
			return {
				phone: '', //电话
				password: '', //密码
				// againPass: '', //确认密码
				state: '登录',
				tip: '',
				value: '',
				telephone:'',//电话
				email:'',
				familyname:'',
				name:'',
				showboardClass: false, //展示输入框
				valueinput: null, //点击的时哪一个输入框
				canlogin:true,
			}
		},
		components: {
			InputKeyBoard
		},
		computed: {
			...mapState(['computer']),
			initcontent(){
				return this[this.valueinput]
			},
		},
		beforeMount() {
			this.setstate('登录')
		},
		methods: {
			changeshowboardClass(e) {
				this.showboardClass = e
			},
			changevalueinput(option) {
				if (!this.computer) {
					this.valueinput = option
					this.showboardClass = true
				}
			},
			// 点击enter把输入框中的值传入对应option中
			enter(e) {
				this[this.valueinput] = e
				this.showboardClass = false
			},
			setstate(option) {
				this.state = option
				if(option=='登录'&&localStorage.getItem('code')){
					let userinfo = JSON.parse(localStorage.getItem('code'))
					this.phone = window.atob(userinfo.title),
					this.password = window.atob(userinfo.code)
				}else{
					this.phone = ''
					this.password = ''
				}
			},
			sj(n) {
				var a = ''; //声明一个数组用来存放生成的数字
				for (let i = 0; i < n; i++) {
					a += parseInt(Math.random() * 10);
				}
				return a
			},
			Visitorlogin() {
				let userID = this.sj(6)
				let userName =this.sj(20)//用户
				let user =  {
					userID,  //当前用户id 唯一
					userName,
				}
				this.$store.commit('user/changeoneidname',userName)
				this.$store.commit('user/changename',userID)
				this.$store.commit('user/changegrade',0)
				// this.loginZEGO(user)
				this.loginvoiveZEGO(user)
				this.$router.replace("/dressing")
				sessionStorage.removeItem('Virtualpalyer')
			},
			// 请求gate服务器看看有没有重复登录
			getUserRepetition(useruid,callback){
				getuser({useruid},(code,data)=>{
					if(code){
						callback(data)
					}else{
						callback({code:500})
					}
				})
			},
			// 登录zego文本房间
			loginZEGO(user){
				console.log(user)
				// this.$store.dispatch('zimStore/login',user).then(login=>{
				// 	console.log(login)
					// if(login){
						this.$store.dispatch('zimStore/createRoom')
						this.$store.dispatch('dialog/onListenerZIM')
					// }else{
					// 	this.tip = "connection fail"
					// }
				// })
			},
			// 登录zego的语音房间
			loginvoiveZEGO(user){
				this.$store.dispatch('zimStore/loginroom',user)
			},
			// 登录
			gologin() {
				if (this.phone && this.password) {
					let obj = {
						phone: this.phone,
						password: this.password
					}
					this.canlogin = false
					// 去数据库验证
					loginiInterface(obj).then(data => {
						this.tip = data.msg
						if (data.code == 200) {
							this.tip = ''
							this.$store.commit('user/changeoneidname', data.userid)
							this.$store.commit('user/changename', this.phone)
							// this.$store.commit('user/changegrade',data.grade)
							// 去长连接验证是否重复登录
							// gate 同步状态请求
							try{
								this.getUserRepetition(data.userid,async (code)=>{
									if(code.code == 200){
										let user =  {
											userID: this.phone, //当前用户id 唯一
											userName:  data.userid,//用户
										}
										// this.loginZEGO(user)
										this.loginvoiveZEGO(user)
										localStorage.setItem('code',JSON.stringify({
											title: window.btoa(this.phone), 
											code:window.btoa(this.password)
										}) )
										getvisitoption({
											userid: [data.userid]
										}).then(playeroption => {
											if (playeroption.code == 0) {
												this.$router.replace("/dressing")
												sessionStorage.removeItem('Virtualpalyer')
											} else if(playeroption.code == 1) {
												sessionStorage.setItem('Virtualpalyer', JSON.stringify(playeroption.data[0]))
												this.$router.replace("/block")
											}else{
												this.tip = "error"
												this.canlogin = true
												console.error(playeroption)
											}
											// console.log(1222)
											// this.setVisit()
										})
									}else{
										this.tip = "The current user has logged in"
										this.canlogin = true
									}
								})
							}catch(e){
								this.tip = "error"
								this.canlogin = true
							}
							
						}else{
							this.canlogin = true
						}
					})
				} else {
					this.tip = "The user has no information. The user name and password cannot be empty"
				}
			},
			// 注册
			goregister() {
				if ( !this.phone || !this.password || !this.familyname || !this.name) {
					this.tip = "can not be empty";
					return 
				}
					// if (this.password == this.againPass) {againPass
						let obj = {
							phone: this.phone,
							password: this.password,
							telephone:this.telephone,//电话
							email:this.email,
							familyname:this.familyname,
							name:this.name,
						}
						registrationiInterface(obj).then(data => {
							this.tip = data.msg
							if (data.code == 200) {
								this.tip = ''
								// alert('注册成功，请登录')
								this.state = '登录'
							}
						})
					// } else {
					// 	this.tip = "两次密码不一致"
					// }
				// } else {
					
				// }
			},
			// 插入浏览量
			// setVisit() {
			// 	let params = {
			// 		type: this.$store.state.computer ? 1 : 0,
			// 		time: getTime()
			// 	}
			// 	setvisit(params).then(data => {
			// 		if (data.code == 200) {
			// 			// this.getVisit()
			// 		}
			// 	})
			// },
			// 获取浏览量
			// getVisit() {
			// 	getvisit().then(data => {
			// 		if (data.code == 200) {
			// 			console.log(data)
			// 			this.looknum = data.visit['count(*)']
			// 		}
			// 	})
			// },
		}
	}
</script>

<style lang="less" scoped>
	.banner {
		position: fixed;
		left: 0;
		width: 100%;
		height: 100%;
		background:  linear-gradient(to bottom right,#d7e2e4,#afcfe0,#62bada, #06a1c8);
	}

	// .shoawd{
	// 	position: fixed;
	// 	left: 0;
	// 	background-color: rgba(0, 0, 0, 0.4);
	// 	width: 100%;
	// 	height: 100%;
	// }
	.from {
		transition: all 2s;
		color: #3e3e3e;
	}

	@media screen and (min-width: 901px) {
		.login_all {
			width: 100%;
			height: 100%;
			.from {
				width: 50%;
				max-width: 500px;
				position: fixed;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -60%);
				background-color: rgba(255, 255, 255);
				border-radius: 20px;
				box-shadow: 1px 1px 1px #e1e1e1;
				
				.login-from {
					width: 100%;
					height: 100%;
					text-align: center;
					padding: 10px 20px;
					display: flex;
					align-items: center;
					flex-direction: column;
					box-sizing: border-box;
					// .input_centent{
					// 	display: flex;
					// 	.input_left{
					// 		width: 50%;
					// 	}
					// 	.input_right{
					// 		width: 50%;
					// 	}
					// }
					.title {
						font-size: 24px;
						display: flex;
						align-items: center;
						// margin-top: 15px;
						width: 90%;
						margin: 15px auto 0;
						.logo {
							border-radius: 50%;
							width: 70px;
							height: 70px;
							overflow: hidden;
							display: flex;
							align-items: center;
							justify-content: center;
							background-color: #fff;
							margin-right: 30px;
							border: 3px #1296db solid;
							
							img {
								width: 70%;

							}
						}
					}

					input {
						width: 90%;
						line-height: 35px;
						background-color: rgba(232, 232, 232, 0.7);
						margin: 15px 0 0;
						text-indent: 4px;
						border: none;
					}

					.tip {
						text-align: left;
						color: red;
						margin-left: 20px;
					}

					.btn {
						width: 90%;
						line-height: 40px;
						color: white;
						background: blue;
						border-radius: 20px;
						margin: 5px auto;
						margin-top: 15px;
					}
					.nonetn {
						width: 90%;
						line-height: 40px;
						color: white;
						background: #a8a8a8;
						border-radius: 20px;
						margin: 5px auto;
					}
					.hint {
						display: flex;
						width: 90%;
						justify-content: space-between;
						font-size: 12px;
						margin: 10px auto;
					}
				}
			}
		}
	}

	@media screen and (max-width: 900px) {
		.login_all {
			width: 100%;
			height: 100%;

			.from {
				width: 46%;
				min-width: 210px;
				max-width: 500px;
				position: fixed;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background-color: rgba(255, 255, 255);
				border-radius: 20px;
				box-shadow: 1px 1px 1px #e1e1e1;

				.login-from {
					width: 100%;
					height: 100%;
					text-align: center;
					padding: 15px 10px;
					display: flex;
					align-items: center;
					flex-direction: column;
					box-sizing: border-box;
					// .input_centent{
					// 	display: flex;
					// 	.input_left{
					// 		width: 50%;
					// 	}
					// 	.input_right{
					// 		width: 50%;
					// 	}
					// }
					.inputname{
						display: flex;
						width: 90%;
						justify-content: space-between;
						margin: 0 auto;
						input{
							width: 48%;
						}
					}
					.title {
						
						display: flex;
						align-items: center;
						width: 90%;
						margin: 0 auto;
						font-size: 20px;
						.name {
							font-size: 19px;
							// width: 180px;
							padding: 0px 10px;
							box-sizing: border-box;
						}

						.logo {
							border-radius: 50%;
							width: 40px;
							height: 40px;
							overflow: hidden;
							display: flex;
							align-items: center;
							justify-content: center;
							background-color: #fff;
							border: 1px #1296db solid;
							img {
								width: 70%;
							}
						}
					}

					input {
						width: 90%;
						line-height: 30px;
						background-color: rgba(232, 232, 232, 0.7);
						margin: 8px 0 0;
						text-indent: 4px;
						border: none;
						font-size: 12px;
					}

					.tip {
						text-align: left;
						color: red;
						margin-left: 20px;
					}

					.btn {
						width: 90%;
						line-height: 34px;
						color: white;
						background: blue;
						border-radius: 20px;
						margin: 5px auto;
						margin-top: 10px;
					}
					.nonetn {
						width: 90%;
						line-height: 40px;
						color: white;
						background:#a8a8a8;
						border-radius: 20px;
						margin: 5px auto;
					}
					.hint {
						display: flex;
						width: 90%;
						justify-content: space-between;
						font-size: 12px;
						margin: 10px auto 0;
					}
				}
			}
		}
	}
</style>