// 导入实例化zimstore的方法
import { zim, generateToken,zg } from '@/util/zegouilt';
const zimStore ={
    namespaced:true,
    state:{
		// // 文本房间配置信息
		// worldroom:{
		// 	roomID:'starbox',
		// 	roomName:'starbox',
		// },
		// 语音房间配置信息  starbox  全局 
		PUBLIC_VOICENAME:'starbox',//
	    // 当前语音房间号  conference 会议厅 sessionStorage.getItem('worldroomvoice')||
		worldroomvoice: 'starbox',
		// 当前需要添加的流
		addarr:[],
		// 当前需要删除的流
		delearr:[],
		// 语音房间用户信息
		userList:[],
		// 登录语音房间是否成功
		resultlogin:false,
		localStreamVideo:null,//当前自己的流
		localStreamAuido:null, //当前语音流
		playingAudiouser:[],//正在说话的人
		auidoflag:false,//是否接受别人的语音
		privatevoice:false,
    },
	mutations:{
		changeplayingAudiouser(state,obj){
			state.playingAudiouser =obj
		},
		changeuserList(state,obj){
			state.userList =obj
		},
		changeaddarr(state,obj){
			state.addarr = obj
		},
		changedelearr(state,obj){
			state.delearr = obj
		},
		changeresultlogin(state,obj){
			state.resultlogin = obj
		},
		changeworldroomvoice(state,obj){
			state.worldroomvoice = obj
			// sessionStorage.setItem('worldroomvoice',obj)
		},
		changelocalStreamVideo(state,obj){
			state.localStreamVideo = obj
		},
		changelocalStreamAuido(state,obj){
			state.localStreamAuido = obj
		},
		changeStreamAuidoArr(state,obj){
			state.localStreamAuido = obj
		},
		// 是否进入私人空间
		changeprivatevoice(state,obj){
			state.privatevoice = obj
		},
		changeauidoflag(state,obj){
			state.auidoflag =obj
		}
	},
    actions:{
		// 登录连接 
		// login(context,payload) {
		// 	// 登录 加生成token
		//     return new Promise((resolve, reject) => { 
		// 		zim
		//         .login(payload, generateToken(payload.userID, 0))
		//         .then((res) => {
		// 			resolve(1)
		//         })
		//         .catch((e)=>{
		// 			console.log(e)
		// 			resolve(0)
		// 		});
		// 	})
		// },
		// 创建文本大房间，或者加入大房间
		// createRoom(context) {
		// 	return zim.enterRoom(context.state.worldroom, {roomDestroyDelayTime:6000}).catch(e=>{});
		// },
		// 退出登录
		// logout(){
		// 	zim.logout();
		// },
		// 登录语音房间
		loginroom(context,payload){
			// 如果没有传房间号，就是公共worldroomvoice
			let roomidstr = payload.roomid?payload.roomid:context.state.PUBLIC_VOICENAME;
			// console.log('房间',roomidstr)
			return  new Promise((resolve, reject) => { 
				zg.loginRoom(roomidstr, generateToken(payload.userID, 0),payload, { userUpdate: true }).then(res=>{
					context.commit('changeresultlogin',res)
					context.commit('changeworldroomvoice',roomidstr)
					// console.log(!!payload.roomid)
					context.commit('changeprivatevoice',!!payload.roomid)
					resolve(1)
				}).catch((e)=>{
					context.commit('changeresultlogin',false)
					resolve(e)
				});
			})
		},
		// 
		// 退出语音房间
		logoutRoom(context){
			let oneidname = sessionStorage.getItem('oneidname')
			
			if(context.state.localStreamVideo){
				zg.stopPublishingStream(oneidname+'video');
				zg.destroyStream(context.state.localStreamVideo)
			}
			if(context.state.localStreamAuido){
				zg.stopPublishingStream(oneidname+'audio');
				zg.destroyStream(context.state.localStreamAuido)
			}
			
			let roomidstr = context.state.worldroomvoice;
			// console.log(222222222222222222222222,roomidstr,context.state.localStreamVideo,context.state.localStreamAuido)
			// let newarr = context.state.playingAudiouser.filter(userid => userid !== oneidname);
			context.commit('changeplayingAudiouser',[])
			
			if(roomidstr){
				// console.log('房间logoutRoom',roomidstr)
				zg.logoutRoom(roomidstr)
			}
			context.commit('changeuserList',[])
			context.commit('changeworldroomvoice',null)
			context.commit('changelocalStreamAuido',null)
			context.commit('changelocalStreamVideo',null)
			context.commit('changeauidoflag',false)
		},
		// 绑定推拉流
		onlisten(context){
			// 推流
			// 获取自己的
			zg.on('publisherStateUpdate', result => {
				// console.log('推流的状态publisherStateUpdate',result)
			})
			// 用户状态更新回调
			zg.on('roomUserUpdate', (roomID, updateType, userList) => {
			    console.log(
			        `roomUserUpdate: room ${roomID}, user ${updateType === 'ADD' ? 'added' : 'left'} `,
			        JSON.stringify(userList),
			    );
			});
			zg.on('playerStateUpdate', result => {
			    // console.log('拉流的状态playerStateUpdate',result)
			})
			zg.on('playQualityUpdate', (streamID, stats) => {
			    // console.log('拉流的质量状态playQualityUpdate',streamID,stats)
			})
			zg.on('publishQualityUpdate',(streamID, stats) => {
			    // console.log('拉流的质量状态playQualityUpdate',streamID, stats)
			})
			// 房间拉流状态改变回调
			zg.on('roomStreamUpdate', async (roomID, updateType, streamList, extendedData) => {
				// console.log('context.start.userList',updateType,context.state.userList)
			    if (updateType == 'ADD') {
					let arr =  [...context.state.userList,...streamList]
					// console.log('add',arr,streamList)
					context.commit('changeuserList',arr)
					context.commit('changeaddarr',streamList)
			    } else if (updateType == 'DELETE') {
					let arr = context.state.userList.filter(function(item) {
						return item !== streamList.streamID
					});
					context.commit('changeuserList',arr)
					context.commit('changedelearr',streamList)
					// context.commit('changedelearr',streamList)
					let newarr =[]
					for(let i=0 ;i < context.state.playingAudiouser.length ;i++){
						for(let j=0;j <streamList.length;j++){
							if(streamList[j] != context.state.playingAudiouser[i] +'audio'){
								newarr.push(context.state.playingAudiouser[i])
							}
						}
					}
					context.commit('changeplayingAudiouser',newarr)
			        // 流删除，停止拉流
					// console.log('delearr',updateType, updateType, streamList, extendedData)
			    }else if (updateType === 'CONNECTED') {
					// console.log(11111,roomID,streamList,extendedData);
					
				}

				if (updateType === 'DISCONNECTED') {
					// console.log(111111,roomID,streamList,extendedData);
				}
			});
			zg.on('soundLevelUpdate',(data)=>{
				// console.log(data)
				data.forEach(item=>{
					if(item.soundLevel>11 && !context.state.playingAudiouser.includes(item.streamID) ){
						// console.log('add',item)
						let newarr = [...context.state.playingAudiouser,item.streamID]
						context.commit('changeplayingAudiouser',newarr)
					}else if(context.state.playingAudiouser.includes(item.streamID) ){
						// console.log('del',item,context.state.playingAudiouser)
						let newarr = context.state.playingAudiouser.filter(userid => userid !== item.streamID);
						// console.log(newarr)
						context.commit('changeplayingAudiouser',newarr)
					}
					
				})
			})
		},
		
		// 打开自己流 oneidname 用户id 
		startZEGO(context, oneidname){
			return  new Promise( async (resolve, reject) => { 
				if(!context.localStream){
					let localStream = null;
					if(oneidname.indexOf('audio') != -1){
						localStream = await  zg.createStream({camera :{audio:true,video:false}});
					}else if(oneidname.indexOf('video') != -1){
						localStream =  await zg.createStream({camera :{audio:false,video:true}});
					}else{
						localStream =  await zg.createStream();
					}
					zg.startPublishingStream(oneidname, localStream)
					resolve(localStream)
				}
			})
		},
		// 关闭自己的流
		stopZEGO(context, {oneidname,localStream}){
			// console.log(oneidname,localStream)
			if(localStream){
				let newarr = context.state.playingAudiouser.filter(userid => userid !== oneidname);
				context.commit('changeplayingAudiouser',newarr)
				zg.stopPublishingStream(oneidname);
				zg.destroyStream(localStream)
			}
		},
		// 拉流
		pullStream(context, streamID){
			return  new Promise( async (resolve, reject) => { 
				// console.log(streamID)
				const remoteStream = await zg.startPlayingStream(streamID);
				if(remoteStream){
					resolve(remoteStream)
				}
			})
		},
		// 停止拉流
		deletStream(context, streamID){
			zg.stopPlayingStream(streamID);
		},
		// 创建共享屏幕流
		createScreenStream(){
			
		}
	}
}
export default zimStore;