import {getgate,getconnector}  from '@/request/pinus.js'
const user ={
    namespaced:true,
    state:{
        oneidname: sessionStorage.getItem('oneidname') ||null,
		name:sessionStorage.getItem('username') ||'unnamed',
		userlist:{} ,
		grade:sessionStorage.getItem('usernamegrade') ||0,
		// userinfo页的控制信息
		userinfo:{
			flag:false,
			AvatarDisplayName:'',
			FirstName:'',//名字
			LastName:'',//
		},
		// callout的控制信息
		callout:{
			flag:false,
			AvatarDisplayName:'', //发起方，或者呼叫方的名字
			callout:true,//是否是发起方（如果是发起方就是只有挂断）
		},
		select_Animation:'',//当前用户的动作名
		select_Emtoe:'',
    },
    mutations:{
		changeselect_Animation(state,obj){
			state.select_Animation = obj;
		},
		changechangeplayerEmtoeArr(state,obj){
			state.select_Emtoe = obj;
		},
		changeuserinfo(state,obj){
			state.userinfo = obj;
		},
        changeoneidname(state,obj){
			state.oneidname = obj;
			sessionStorage.setItem('oneidname',obj)
		},
		changename(state,obj){
			state.name=obj;
			sessionStorage.setItem('username',obj)
		},
		changegrade(state,obj){
			state.grade=obj;
			sessionStorage.setItem('usernamegrade',obj)
		},
		changeuserlist(state,obj){
			let newuser = {
				...state.userlist,
				...obj
			}
			state.userlist = newuser
			sessionStorage.setItem('userlist' , JSON.stringify(newuser))
		}
    }
}
export default user;