import {
	post,
	get
} from '@/request/index.js';
// import axios from 'axios'
//插入浏览量
export const setvisit = (params) => post('/visit/into', params)
// 获取浏览器
export const getvisit = (params) => get('/visit')
// 获取点赞数
export const getgiveup = (params) => get('/visit/giveup')
// 点赞
export const giveup = (params) => post('/visit/giveupLike', params)
// 获取视频或图片
export const getvideo = (params) => get('/visit/getvideo', params)
// 增加点击量
export const addclike = (params) => get('/visit/addclike', params)
// 登录
export const loginiInterface = (params) => post('/user/visitlogin', params)
//注册
export const registrationiInterface = (params) => post('/user/visitadd', params)
// 获取用户虚拟形象
export const getvisitoption = (params) => post('/visit/getvisitoption', params)
// 获取人物形象
export const getbasicsmodeloption = (params) => post('/visit/getbasicsmodeloption')
// 获取组件配置项
export const gettypemodeloption = (params) => post('/visit/gettypemodeloption')
// 设置当前用户的配置角色
export const setplayeroption = (params) => post('/visit/setplayeroption', params)
// 上传ppt
// export const uploadppt = (params,config) => post('/visit/uploadppt', params,config)
// 获取临时密钥
export const getprove = (params) => post('/prove')
// 查询用户信息
export const getuser = (params) => post('/user/getuser',params)

