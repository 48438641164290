import {randomnum } from '@/util/compass.js'
const placeInfo ={
    namespaced:true,
    state:{
	   // 每个地区配置 （状态同步的）
       placeInfo:[
		   {
			   place: 'Starbox Virtual World',
			   position: [randomnum(0,2), 0, randomnum(15,2)],
			    // position: [0,0,0],
			   to:'Starbox Virtual Mall',
			   angle:{rotate:0,quaternion:[0,0,0,1]},
			   Animation:'Animation_Idle'
		   }, {
			   place: 'Starbox Virtual Mall',
			   position: [randomnum(-10,2), 0.1, randomnum(55,2)],
			   to:'Starbox Virtual World',
			   angle:{rotate:0,quaternion:[0,0,0,1]},
			   Animation:'Animation_Idle'
		   },{
			   place: 'Starbox Virtual Conference Hall',
			   position: [randomnum(-11.8,2), 2.6, 34.2],
			    // position: [0,0,0],
			   to:'Starbox Virtual World',
			    angle:{rotate:0,quaternion:[0,0,0,1]},
			   Animation:'Animation_Idle'
		   },{
			   place: 'SkyPark',
			   position: [randomnum(-1.5,1), 0.1,10],
			    // position: [0,0,0],
			   to:'Starbox Virtual World',
			    angle:{rotate:0,quaternion:[0,0,0,1]},
			   Animation:'Animation_Idle'
		   },
		   {
			   place: 'Enterprise Showroom',
			   position: [randomnum(-3,1), 0.1, 15],
			   to:'Starbox Virtual World',
			    angle:{rotate:0,quaternion:[0,0,0,1]},
			   Animation:'Animation_Idle'
		   },
		   {
			   place: 'Exhibition Center',
			   position: [randomnum(8,1), 0.1, -18],
			   to:'Starbox Virtual World',
			    angle:{rotate:0,quaternion:[0,0,0,1]},
			   Animation:'Animation_Idle'
		   },
	   ],
	   nowinitplace:'Starbox Virtual World'
    },
    mutations:{
		changenowinitplaceInfo(state,obj){
			state.nowinitplaceInfo = obj;
		},
		changenowinitplace(state,obj){
			state.nowinitplace = obj;
		},
		changenewrouter(state,obj){
			state.newrouter = obj;
		}
    }
}
export default placeInfo;