export default{
	load:{
		name:'Virtual Mall',
		name1:'Virtual World',
		name2:'Conference Hall',
		name3:'SkyPark',
		name4:'Enterprise Showroom',
		name5:'Exhibition Center',
		btn:'Click to enter the scene'
	},
	index:{
		alert:'Current popularity value',
		firstperson:'first person',
		thirdperson:'third person',
		avatardIY:'Avatar DIY',
		animationrun:'Run',
		animationwalk:'Walk',
		animationJump:'Jump'
	},
	message:{
		mesbtn1:'Slide the virtual rocker to move the current position',
		mesbtn2:'Swiping left and right moves the view'
	},
	maplist:{
		beautyzone:'Make-up area',
		digitalarea:'Digital area',
		Jewelrysection:'Jewelry area',
		HomeAppliancearea:'Appliance area',
		Garmentdistrict:'Clothing area',
		emojis:'emojis',
		emotes:'emotes',
	},
	alert:{
		prompt:'prompt',
		message:'Do you want to move to ',
		cancel:'No',
		confirm:'Yes'
	},
	virtualDressing:{
		save:'Finish',
		Cancel:'Cancel',
		title:'Change Avatar',
		player:'Avatar',
		Eyes:'Eyes',
		skin:'Skin',
		hair:'Hair',
		face:'Head Shape',
		eyebrow:'Eyebrow',
		Coat:'Tops',
		Trousers:'Bottoms',
		shoe:'Shoes',
		Male:'Male',
		Female:'Female',
		next:'Next'
	},
	login:{
		login:'Login',
		Visitorlogin:'Visitor login',
		Register:'Register',
		tip:'Have an account?Login',
		title:'Starbox Virtual Mall',
		// title:'ARx Virtual Mall'.
		// title:'CONNEX METAVERSE'
	},
	tip:{
		tip:'in a private voice room'
	},
	userinfo:{
		AvatarDisplayName:'Avatar Display Name',
		FirstName:'First Name',
		LastName:'Last Name',
		title:'User Profile'
	}
}