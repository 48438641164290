<template>
	<div :class="keyboardClass"></div>
</template>

<script>
	import Keyboard from 'simple-keyboard';
	import 'simple-keyboard/build/css/index.css';
	import layout from 'simple-keyboard-layouts/build/layouts/chinese'; // 中文输入法

	export default {
		name: 'SimpleKeyboard',
		props: {
			keyboardClass: {
				default: 'simple-keyboard',
				type: String,
			},
			input: {
				default: '',
			},
			maxLength: {
				default: ''
			},
		},
		data: () => ({
			keyboard: null,
			displayDefault: {
				'{bksp}': 'backspace',
				'{lock}': 'caps',
				'{enter}': 'enter',
				'{tab}': 'tab',
				'{shift}': 'shift',
				'{change}': 'Chinese',
				'{space}': '    ',
				'{clear}': 'clear',
				'{close}': 'close',
			},
		}),
		mounted() {
			this.keyboard = new Keyboard(this.keyboardClass, {
				onChange: this.onChange,
				onKeyPress: this.onKeyPress,
				layoutCandidates: null,
				layout: {
					// 默认布局
					default: [
						'` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
						'{tab} q w e r t y u i o p [ ] \\',
						"{clear} a s d f g h j k l ; ' {change}",
						'{shift} z x c v b n m , . / {shift}',
						'{close} {space} {enter}',
					],
					// shift布局
					shift: [
						'~ ! @ # $ % ^ & * ( ) _ + {bksp}',
						'{tab} Q W E R T Y U I O P { } |',
						'{clear} A S D F G H J K L : " {change}',
						'{shift} Z X C V B N M < > ? {shift}',
						'{close} {space} {enter}',
					],
				},
				// 按钮展示文字
				display: this.displayDefault,
				// 按钮样式
				buttonTheme: [{
						class: 'hg-red close',
						buttons: '{close}',
					},
					{
						class: 'change',
						buttons: '{change}',
					},
				],
				// 输入限制长度
				maxLength: this.maxLength,
			});
		},
		methods: {
			onChange(input) {
				this.keyboard.setInput(input);
				this.$emit('onChangeKeyboard', input);
			},
			// 点击键盘
			onKeyPress(button, $event) {
				// 点击关闭
				 if (button === '{change}') {
					 $event.stopPropagation()
					  $event.preventDefault()
					// 切换中英文输入法
					if (this.keyboard.options.layoutCandidates !== null) {
						this.$set(this.displayDefault, '{change}', 'Chinese');
						// 切换至英文
						this.keyboard.setOptions({
							layoutCandidates: null,
							display: this.displayDefault,
						});
					} else {
						// 切换至中文
						this.$set(this.displayDefault, '{change}', 'English');
						this.keyboard.setOptions({
							layoutCandidates: layout.layoutCandidates,
							display: this.displayDefault,
						});
					}
				} else if (button === '{clear}') {
					this.keyboard.clearInput();
				} else {
					// let value =$event.target.offsetParent.parentElement.children[0].children[0].value;
					// // 输入框有默认值时，覆写
					// if (value) {
					// 	this.keyboard.setInput(value);
					// }
					this.$emit('onKeyPress', button);
				}
				if (button === '{shift}' || button === '{lock}') this.handleShift();
			},
			// 切换shift/默认布局
			handleShift() {
				let currentLayout = this.keyboard.options.layoutName;
				let shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

				this.keyboard.setOptions({
					layoutName: shiftToggle,
				});
			},
		},
		watch: {
			input(input) {
				this.keyboard.setInput(input);
			},
		},
	};
</script>

<style lang="less">
	@deep: ~'>>>';
	.keyboardClass{
		
	}
	.hg-theme-default {
		width: 60%;
		color: black;
		// margin-top: 60px;
		font-size: 14px;
		.hg-button-enter{
			background: #db3e5d;
			color: white;
		}
		.hg-button {
			&.hg-red {
				

				&.close {
					max-width: 200px;
				}
			}

			&.change {
				max-width: 200px;
			}
		}
	}
</style>
