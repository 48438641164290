<template>
	<div :class="['dialogBoxbig',Keyboardshow?'':'pointer']" ref="singleSelect">
		<div class="dialogbox_content">
			<div class="voice">
				<img v-if="localStreamAuido" @click="stopZEGO" src="../../assets/imgs/commonui/voice.png" alt="">
				<img v-else  @click="startZEGO"  src="../../assets/imgs/commonui/close_voice.png" alt="">
			</div>
			<!-- <div class="dialogBox" :style="{background:publicChannel?'rgba(0,123,255,0.6)':'rgba(0,255,127,0.6)'}"> -->
			<div class="dialogBox">
				<div class="dialogBoxselect">
					<div class="person" v-for="item in chatPrivateArr" @click="changechatchannl(item)" :style="{background:chatchannl.name == item.name?'rgba(255, 255, 255, 0.4)':'rgba(184, 184, 184, 0.4)'}">
						<img v-if='item.newinformation'  class="warn" src="/img/email-unread.png" alt="" />
						{{item.name}}
					</div>
				</div>
				<input  @focus="onchangestatetyping(true)" @blur="onchangestatetyping(false)" v-if="computer" type="text"
					v-model="content">
				<input  v-else @click.stop="changeshowboardClass(true)" type="text" v-model="content" readonly>
				
				<div v-if="content.length != 0" class="btn" @click="send"><img src="/img/send.png" alt=""></div>
				<div v-else class="btnnone"><img src="/img/send.png" alt=""></div>
			</div>
			<div class="dialog1">
				<img v-if="auidoflag" @click="cloneaudio" src="../../assets/imgs/commonui/audio.png" alt="">
				<img v-else  @click="openaudio"  src="../../assets/imgs/commonui/cloneaudio.png" alt="">
			</div>
		</div>
		<!-- 显示对话区域 -->
		<div class="content_name"  ref="rightBody">
			<div class="classcontent" v-for="item in showdialoglist">
				<div class="name">{{item.name}}:</div>
				<div class="nameType" :style="{color:item.type?'#007bff':'#00ff7f'}">
					<!-- {{item.type?'public':dialogueChannelName == 'conference'?'conference':'private' }}  -->
					<span class="color:#57caff">{{item.toobject?'to':''}}</span> {{item.title}}
					
				</div>
				<div class="talk" >{{item.content}}</div>
				<img  v-if="item.toobject &&item.name !=name" class="remind" src="/img/remind.png" alt="" />
			</div>
			
		</div>
		<InputKeyBoard :initcontent="content" v-if="Keyboardshow" @enter="enter" @changeshowboardClass='changeshowboardClass' />
		<audio id="local-audio"  style="display: block;"  ></audio>
		<video  class="voice1"  style="display: none;" id="local-video"  playsinline muted></video>
		<div id="audio" class="audio">
			
		</div>
		<div id="video" class="audio">
			
		</div>
	</div>

</template>

<script>
	import InputKeyBoard from '@/components/SimpleKeyboard/InputKeyBoard.vue'
	import {
		mapState,
		mapMutations
	} from 'vuex'
	import {
		chatroom,
		getgate,
		getconnector,
		adduserchannel,
		senddialoguechannel
	} from '@/request/pinus.js'
	import {
		zim,zg
	} from '@/util/zegouilt';
	export default {
		data() {
			return {
				content: '',
				// show: false,
				Keyboardshow: false,
				// showdialogBox: true,
				// conversationType: 1, //会话类型  0单聊 1房间 2群聊
				// voiceflag:false,//默认不开启语音
				// auidoflag:false,//是否接受别人的语音
				audioarr:[],
				videoarr:[],
				// localStream:null,
				localStreamarr:[],//其他人的流
			}
		},
		components: {
			InputKeyBoard
		},
		mounted() {
			// console.log(1111111111111112)
			this.initaudio(this.userList)
			// 发送
			window.addEventListener('keydown', this.keydownListenefn.bind(this), false);
			zg.setSoundLevelDelegate(true, 500);
			setTimeout(()=>{
				// 默认进入公共房间
				// adduserchannel(this.oneidname,this.publicRoom,true,()=>{})
				this.$store.commit('dialog/addplayerpublic',this.chatPrivateArr[0])
				// 特别 conference房间处理
				let conferencearr = this.chatPrivateArr.filter((item)=>{return item.name == 'conference'})
				if(conferencearr.length !=0){
					// 把人加入私人房间
					this.$store.commit('dialog/addplayerpublic',conferencearr[0])
				}
				// 开启监听
				this.$store.dispatch('dialog/onListenerZIM')
			},700)
			
		},
		computed: {
			...mapState(['computer','isenter']),
			...mapState('user', ['oneidname', 'name']),
			...mapState('dialog', ['dialoglist','voiceflag','dialogueChannelName','publicChannel','publicRoom','chatPrivateArr','chatchannl']),//,'voiceflag'
			...mapState('zimStore', ['localStreamAuido','auidoflag','worldroom','userList','resultlogin','addarr','delearr']),
			showdialoglist(){
				return this.dialoglist.slice(-3);
			}
		},
		watch: {
		    addarr(newMessage) {
				for(let i=0;i<newMessage.length;i++){
					if(newMessage[i].streamID.indexOf('audio') != -1){
						this.addstream(newMessage[i])
					}
					
				}
		    },
			delearr(newMessage) {
				for(let i=0;i<newMessage.length;i++){
					if(newMessage[i].streamID.indexOf('audio') != -1){
						this.deletstream(newMessage[i])
					}
				}
			},
		},
		
		beforeDestroy(){
			this.cloneZEGO()
			this.stopZEGO()
			zg.setSoundLevelDelegate(false);
			// 清楚私人房间
			let conferencearr = this.chatPrivateArr.filter((item)=>{return item.name == 'conference'})
			if(conferencearr.length !=0){
				// 把人加入私人房间
				this.$store.commit('dialog/changechatPrivateArrDele',conferencearr[0])
			}
			let privatearr = this.chatPrivateArr.filter((item)=>{return item.name == 'private'})
			if(privatearr.length !=0){
				// 把人加入私人房间
				this.$store.commit('dialog/changechatPrivateArrDele',privatearr[0])
			}
		},
		methods: {
			...mapMutations('dialog',['changepublicChannel','changechatchannl']),
			// 初始化创建video和audio
			async initaudio(userlist){
				// console.log('init',userlist)
				let audioparent = document.getElementById('audio');
				// let videoparent = document.getElementById('video');
				for(let i=0;i<userlist.length;i++){
					if(userlist[i].streamID.indexOf('audio') != -1){
						this.addstream(userlist[i])
					}
					// 拉对应的用户的流
				}
			},
			// 打开自己流
			async startZEGO(){
				zg.createStream({
					camera: {
						audio: true,
						video: false
					}
				}).then((data) => {
					// console.log(data)
					this.$store.commit('zimStore/changelocalStreamAuido',data)
					zg.startPublishingStream(this.oneidname+'audio', data)
				}).catch(e => {
					// console.log(e.msg)
					this.$store.commit('changtooltipshow', {
						state: true,
						content: e.msg
					})
				})
			},
			// 关闭自己的流
			async stopZEGO(){
				this.$store.dispatch('zimStore/stopZEGO',{oneidname:this.oneidname+'audio',localStream:this.localStreamAuido})
				this.$store.commit('zimStore/changelocalStreamAuido',null)
			},
			// 销毁时全部停止拉流的状态
			cloneZEGO(){
				this.cloneaudio()
				if(this.localStreamAuido){
					this.stopZEGO()
				}
				for(let i=0;i<this.userList.length;i++){
					zg.stopPlayingStream(this.userList[i].streamID)
				}
			},
			// 添加流
			async addstream(user){
				// console.log(user.streamID)
				let audioparent = document.getElementById('audio');
				// let videoparent = document.getElementById('video');
				// 拉对应的用户的流
			    const remoteStream = await	this.$store.dispatch('zimStore/pullStream',user.streamID)
				if(remoteStream){
					// 创建音频元素
					let audio = document.createElement('audio');
					audio.setAttribute('id', user.streamID);
					audio.srcObject = remoteStream;
					audioparent.appendChild(audio);
					this.audioarr.push(audio)
					if(this.auidoflag){
						
						audio.addEventListener('loadedmetadata', () => {
						  audio.play();
						});
					}
				}
				
			},
			// 删除流
			deletstream(user){
				// console.log(333333333,user)
				zg.stopPlayingStream(user.streamID)
				for(let i=0;i<this.audioarr.length;i++){
					if(this.audioarr[i].id == user.streamID){
						this.audioarr[i].remove()
						this.audioarr.splice(i,1);
					}
				}
			},
			openaudio(){
				for(let i=0;i<this.audioarr.length;i++){
					this.audioarr[i].play()
				}
				this.$store.commit('zimStore/changeauidoflag',true)
			},
			cloneaudio(){
				for(let i=0;i<this.audioarr.length;i++){
					this.audioarr[i].pause()
				}
				this.$store.commit('zimStore/changeauidoflag',false)
			},
			// 点击回车
			enter(e) {
				this.content = e;
				this.changeshowboardClass(false)
				this.show = true
			},
			// 隐藏或展示键盘
			changeshowboardClass(e) {
				setTimeout(() => {
					this.Keyboardshow = e
					// this.showdialogBox = !e
				}, 500)
			},
			// pc端方法
			// 点击发送或回车
			keydownListenefn(e) {
				if (e.code == 'Enter') {
					this.send()
				}
			},
			onchangestatetyping(e) {
				this.$store.commit('onchangestatetyping', e)
				this.show = e
			},
			// 点击
			send(data) {
				if (this.content.length != 0) {
					let obj = {
						name: this.name,
						content: this.content,
						type: this.chatchannl.publicChannel,
						clientSid:new Date().getTime(),
						toobject:this.chatchannl.toobject,
						title:this.chatchannl.title,
					}
					
					senddialoguechannel(obj,()=>{
						console.log(obj)
						// this.$store.commit('dialog/changedialoglist', obj)
						this.show = true
						this.Keyboardshow = false
						this.content = '';
					})
				}
				// if (this.content.length != 0) {
				// 	let message1 = {
				// 		type: 1, //消息类型 1文本 2 二进制 0未知 20弹窗 11image 12 文件 13音频  14视频 30 系统消息 200自定义信息
				// 		message: this.content ||null,
				// 		// timestamp: new Date().getTime()
				// 	}
					
				// 	zim.sendMessage(message1, this.worldroom.roomID,1, {
				// 			priority: 1
				// 		})
				// 		.then(({
				// 			message
				// 		}) => {
				// 			console.log(message)
				// 			let obj = {
				// 				name: message.senderUserID,
				// 				content: message.message,
				// 				type: 'Other',
				// 				clientSid:new Date().getTime()
				// 			}
				// 			this.$store.commit('dialog/changedialoglist', obj)
				// 			this.show = true
				// 			this.Keyboardshow = false
				// 		})
				// 		.catch(function(err) {
				// 			// this.show = true
				// 			// this.Keyboardshow = false
				// 			console.log(err,message1,message)
				// 			// if(6000322)
				// 		});

				// 	this.content = '';
				// }
			},
		}
	}
</script>

<style lang="less" >
	.pointer {
		pointer-events: none;
	}
	// @media screen and (orientation:portrait) {
	
	/*竖屏样式*/
	.landscape {
		transform-origin: 0 0;
		transform: rotateZ(90deg) translateY(-100%);
	}
	
	// }
	@media screen and (min-width: 901px) {
		.content_name {
			transition: all .5s ease;
			position: fixed;
			top: 20px;
			left:20px;
			.classcontent{
				// display: flex;
				line-height: 20px;	
				margin-bottom: 5px;
				padding: 5px;
				border-radius: 5px;
				position: relative;
				min-width: 200px;
				// align-items: center;
				// justify-content: center;
				background-color: rgba(255, 255, 255, 0.2);
				// background-color: rgba(0, 0, 0, 0.2);
				.name {
					color: aquamarine;
				}
				.talk {
					max-width: 300px;
					color: white;
					word-wrap:break-word;
					text-align: left;
				}
			}
		}
		.nameType{
			position: absolute;
			right: 0;
			top: 0;
			font-size: 12px;
			transform: scale(0.9);
			
			
		}
		.remind{
			position: absolute;
			right: -50px;
			width: 30px;
			height: 30px;
			animation-duration: 1.5s;
			animation-name: slidein;
			animation-direction: alternate;
			animation-iteration-count:infinite;
			top: 20%;
			transform: translateY(-50%);
		}
		@keyframes slidein {
			from {
				transform: rotateZ(30deg)
			}
			50%{
				transform: rotateZ(0deg)
			}
			to {
				transform: rotateZ(-30deg)
			}
		}
		.voice1{
			position: fixed;
			pointer-events: auto;
			right: 0;
			bottom: 0px;
			width: 200px;
			height: 200px;
			z-index: 10001;
			// background-color: #ffffff;
		}
		.audio{
			position: fixed;
			pointer-events: auto;
			right: 0;
			bottom: 200px;
			z-index: 10001;
			display: flex;
			video{
				width: 200px;
				height:  200px;
			}
		}
		.dialogbox_content{
			position: fixed;
			bottom: 20px;
			left: 50%;
			transform: translateX(-50%);
			width: 450px;
			
			display: flex;
			align-items: center;
			justify-content: space-between;
			pointer-events: auto;
			.voice{
				width:40px;
				height:40px;
				line-height: 40px;
				background-color: white;
				border-radius: 50%;
				text-align: center;
				margin-left: 30px;
				img{
					vertical-align: middle;
					width: 70%;
					height: 70%;
				}
			}
			.dialog1{
				width:40px;
				height:40px;
				line-height: 40px;
				background-color: white;
				border-radius: 50%;
				text-align: center;
				margin-right: 30px;
				img{
					vertical-align: middle;
					width: 70%;
					height: 70%;
				}
			}
			.dialogBox {
				font-size: 12px;
				display: flex;
				align-items: center;
				height: 50px;
				width: 250px;
				justify-content: space-between;
				background-color: rgba(255, 255, 255, 0.4);
				border-radius: 25px;
				padding: 0 20px;
				input {
					width: 210px;
					line-height: 30px;
					background-color: rgba(255, 255, 255, 0.7);
					margin: 15px 0;
					text-indent: 4px;
					border: none;
					margin-right: 2px;
					border: none;
				}
		
				.btn {
					line-height: 35px;
					width: 20%;
					width: 35px;
					border-radius: 50%;
					color: white;
					background: blue;
					// border-radius: 5px;
					// margin: 5px auto;
					text-align: center;
					img{
						width: 20px;
						height: 20px;
						vertical-align: middle;
					}
				}
		
				.btnnone {
					line-height: 35px;
					width: 20%;
					width: 35px;
					border-radius: 50%;
					color: white;
					background: #5d5d5d;
					// border-radius: 5px;
					// margin: 5px auto;
					text-align: center;
					img{
						width: 20px;
						height: 20px;
						vertical-align: middle;
					}
				}
			}
			.dialogBoxselect{
				position: absolute;
				display: flex;
				top: -30px;
				div{
					background-color: rgba(255, 255, 255, 0.4);
					// background-color: rgba(0, 0, 0, 0.2);
					border-radius:10px 10px 0 0;
					height: 30px;
					// width: 60px;
					text-align: center;
					padding:0 5px ;
					line-height: 30px;
				}
				.public{
					background-color: rgba(255, 255, 255, 0.4);
				}
				.private{
					// background-color: rgba(0, 0, 0, 0.4);
				}
				.person{
					background-color: rgba(184, 184, 184, 0.4);
					position: relative;
					.warn{
						width: 25px;
						height: 25px;
						position: absolute;
						top: -30px;
						left: 50%;
						// transform: translateX(-50%);
						animation-duration: 1.5s;
						animation-name: warn;
						animation-direction: alternate;
						animation-iteration-count:infinite;
					}
				}
				@keyframes warn {
					from {
						transform: translateY(8px) translateX(-50%);
					}
					to {
						transform: translateY(0px) translateX(-50%);
					}
				}
			}
		}
	}
	@media screen and (max-width: 900px)  {
		.dialogBoxselect{
			position: absolute;
			display: flex;
			top: -20px;
			div{
				background-color: rgba(255, 255, 255, 0.4);
				// background-color: rgba(0, 0, 0, 0.2);
				border-radius:10px 10px 0 0;
				height: 20px;
				// width: 50px;
				padding:0 5px ;
				text-align: center;
				line-height: 20px;
				font-size: 12px;
				// transform: scale(0.8);
				// padding: 5px;
			}
			.public{
				background-color: rgba(255, 255, 255, 0.4);
			}
			.private{
				background-color: rgba(0, 0, 0, 0.4);
				position: relative;
				
			}
			.person{
				background-color: rgba(184, 184, 184, 0.4);
				position: relative;
				.warn{
					width: 25px;
					height: 25px;
					position: absolute;
					top: -30px;
					left: 50%;
					// transform: translateX(-50%);
					animation-duration: 1.5s;
					animation-name: warn;
					animation-direction: alternate;
					animation-iteration-count:infinite;
				}
			}
			@keyframes warn {
				from {
					transform: translateY(8px) translateX(-50%);
				}
				to {
					transform: translateY(0px) translateX(-50%);
				}
			}
		}
		.content_name {
			transition: all .5s ease;
			position: fixed;
			top: 20px;
			left:20px;
			.nameType{
				position: absolute;
				right: 0;
				top: 0;
				font-size: 12px;
				transform: scale(0.7);
			}
			.classcontent{
				// display: flex;
				position: relative;
				line-height: 15px;	
				font-size: 12px;
				padding: 4px;
				border-radius: 4px;
				margin-bottom: 5px;
				min-width:100px;
				background-color: rgba(255, 255, 255, 0.2);
				.name {
					color: aquamarine;
				}
				.talk {
					white-space:normal;
					max-width: 200px;
					color: white;
					word-wrap:break-word;
					text-align: left;
				}
			}
		}
		.remind{
			position: absolute;
			right: -30px;
			width: 25px;
			height: 25px;
			animation-duration: 1.5s;
			animation-name: slidein;
			animation-direction: alternate;
			animation-iteration-count:infinite;
			top: 20%;
			transform: translateY(-50%);
		}
		@keyframes slidein {
			from {
				transform: rotateZ(30deg)
			}
			50%{
				transform: rotateZ(0deg)
			}
			to {
				transform: rotateZ(-30deg)
			}
		}
		.voice1{
			position: fixed;
			pointer-events: auto;
			right: 0;
			bottom: 0px;
			width: 80px;
			height: 80px;
			z-index: 10001;
			// background-color: #ffffff;
		}
		.audio{
			position: fixed;
			pointer-events: auto;
			right: 0;
			bottom: 80px;
			z-index: 10001;
			display: flex;
			video{
				width: 80px;
				height:  80px;
			}
		}
		.dialogbox_content{
			position: fixed;
			bottom: 20px;
			left: 50%;
			transform: translateX(-50%);
			width: 380px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			pointer-events: auto;
			.voice{
				width:35px;
				height:35px;
				line-height: 35px;
				background-color: white;
				border-radius: 50%;
				text-align: center;
				margin-left: 30px;
				img{
					vertical-align: middle;
					width: 70%;
					height: 70%;
				}
			}
			.dialog1{
				width:35px;
				height:35px;
				line-height: 35px;
				background-color: white;
				border-radius: 50%;
				text-align: center;
				margin-right: 30px;
				img{
					vertical-align: middle;
					width: 70%;
					height: 70%;
				}
			}
			.dialogBox {
				font-size: 12px;
				display: flex;
				align-items: center;
				height: 40px;
				width: 200px;
				justify-content: space-between;
				background-color: rgba(255, 255, 255, 0.4);
				border-radius: 20px;
				padding: 0 15px;
				font-size: 12px;
				input {
					width:170px;
					line-height: 25px;
					background-color: rgba(255, 255, 255, 0.7);
					margin: 15px 0;
					text-indent: 4px;
					border: none;
					margin-right: 2px;
					border: none;
					border-radius: 1px;
				}
		
				.btn {
					line-height: 25px;
					width: 25px;
					border-radius: 50%;
					color: white;
					background: blue;
					// border-radius: 1px;
					// margin: 5px auto;
					text-align: center;
					img{
						width: 15px;
						height: 15px;
						vertical-align: middle;
					}
				}
		
				.btnnone {
					line-height: 25px;
					width: 25px;
					border-radius: 50%;
					color: white;
					background: #5d5d5d;
					// border-radius: 1px;
					// margin: 5px auto;
					text-align: center;
					img{
						width: 15px;
						height: 15px;
						vertical-align: middle;
					}
				}
			}
		}
	}
	// @media screen and (min-width: 901px) {
	// 	.dialogBoxbig {
	// 		position: relative;
	// 		width: 100%;
	// 		height: 100%;
	// 		z-index: 99999;
	// 	}

	// 	.dialogBox_all {
	// 		position: fixed;
	// 		pointer-events: auto;
	// 		left: 110px;
	// 		bottom: 0;
	// 		width: 20%;
	// 		// height: 130px;
	// 		font-size: 12px;
	// 		background-color: rgba(255, 255, 255, 0.4);
	// 		border-radius: 0 5px 0 0;
	// 		// box-shadow: 1px 1px 1px #565656;
	// 		border: 3px solid #ffffff;
	// 		border-bottom: none;
	// 		padding: 0 5px;
	// 		text-align: center;
	// 		// box-sizing: border-box;
	// 	}
	// 	.voice{
	// 		position: fixed;
	// 		pointer-events: auto;
	// 		left: 0;
	// 		bottom: 0;
	// 		width: 110px;
	// 		height:  110px;
	// 		background-color: #ffffff;
	// 		border-radius: 50%  50% 0 0% ;
	// 		text-align: center;
	// 		line-height: 120px;
	// 		img{
	// 			vertical-align: middle;
	// 			width: 60px;
	// 			height: 60px;
	// 		}
	// 	}
	// 	.voice1{
	// 		position: fixed;
	// 		pointer-events: auto;
	// 		left: 0;
	// 		top: 0px;
	// 		width: 200px;
	// 		height: 200px;
	// 		z-index: 10001;
	// 		// background-color: #ffffff;
	// 	}
	// 	#audio{
	// 		position: fixed;
	// 		pointer-events: auto;
	// 		left: 0;
	// 		top: 200px;
	// 		z-index: 10001;
	// 		width: 200px;
	// 		height:  200px;
	// 		display: flex;
	// 		video{
	// 			width: 200px;
	// 			height:  200px;
	// 		}
	// 	}
	// 	.content_name {
	// 		transition: all .5s ease;

	// 		div {
	// 			display: flex;
	// 			line-height: 30px;

	// 			.name {
	// 				color: aquamarine;
	// 			}

	// 			.talk {
	// 				color: white;
	// 				text-align: left;
	// 			}
	// 		}
	// 	}

	// 	.none {
	// 		height: 0;
	// 		overflow: hidden;
	// 	}

	// 	.show {
	// 		height: 140px;
	// 		overflow-y: auto;
	// 		overflow-x: hidden;
	// 	}

	// 	.dialog {
	// 		position: absolute;
	// 		left: -3px;
	// 		top: -30px;
	// 		height: 30px;
	// 		width: 60px;
	// 		text-align: center;
	// 		line-height: 30px;
	// 		color: #000000;
	// 		background-color: #ffffff;
	// 		border-radius: 10px 10px 0 0;
	// 	}
	// 	.dialog1 {
	// 		position: absolute;
	// 		right: -3px;
	// 		top: -40px;
	// 		height: 40px;
	// 		width: 40px;
	// 		text-align: center;
	// 		line-height: 30px;
	// 		color: #000000;
	// 		background-color: #ffffff;
	// 		border-radius: 10px 10px 0 0;
	// 		line-height: 40px;
	// 		img{
	// 			vertical-align: middle;
	// 			width: 60%;
	// 			height: 60%;
	// 		}
	// 	}
		
	// 	.dialogBox {
	// 		font-size: 12px;
	// 		display: flex;
	// 		align-items: center;
	// 		height: 50px;
	// 		width: 100%;
	// 		justify-content: space-between;

	// 		input {
	// 			width: 75%;
	// 			line-height: 30px;
	// 			background-color: rgba(255, 255, 255, 0.7);
	// 			margin: 15px 0;
	// 			text-indent: 4px;
	// 			border: none;
	// 			margin-right: 2px;
	// 			border: none;
	// 		}

	// 		.btn {
	// 			line-height: 30px;
	// 			width: 20%;
	// 			color: white;
	// 			background: blue;
	// 			border-radius: 5px;
	// 			margin: 5px auto;
	// 			text-align: center;
	// 		}

	// 		.btnnone {
	// 			line-height: 30px;
	// 			width: 20%;
	// 			color: white;
	// 			background: #5d5d5d;
	// 			border-radius: 5px;
	// 			margin: 5px auto;
	// 			text-align: center;
	// 		}
	// 	}
	// }

	// @media screen and (max-width: 900px) {
	// 	.dialogBoxbig {
	// 		position: fixed;
	// 		left: 0;
	// 		top: 0;
	// 		width: 100%;
	// 		height: 100%;
	// 		z-index: 10001;
	// 	}

	// 	.dialogBox_all {
	// 		position: fixed;
	// 		left: 220px;
	// 		bottom: 0;
	// 		width: 30%;
	// 		font-size: 12px;
	// 		background-color: rgba(255, 255, 255, 0.4);
	// 		border-radius: 0 5px 0 0;
	// 		border: 3px solid #ffffff;
	// 		border-bottom: none;
	// 		padding: 5px;
	// 		pointer-events: auto;
	// 		z-index: 10001;
	// 	}
	// 	.voice1{
	// 		position: fixed;
	// 		pointer-events: auto;
	// 		left: 0;
	// 		top: 80px;
	// 		width: 80px;
	// 		height:  80px;
	// 		z-index: 10001;
	// 		background-color: rgba(255, 255, 255, 0.4);
	// 	}
	// 	#audio{
	// 		position: fixed;
	// 		pointer-events: auto;
	// 		left: 0;
	// 		top: 0px;
	// 		width:80px;
	// 		height: 80px;
	// 		z-index: 10001;
	// 		display: flex;
	// 		background-color: rgba(255, 255, 255, 0.4);
	// 		video{
	// 			width: 80px;
	// 			height:  80px;
	// 		}
	// 	}
	// 	.voice{
	// 		position: fixed;
	// 		pointer-events: auto;
	// 		left:  140px;
	// 		bottom: 0;
	// 		width: 80px;
	// 		height:  80px;
	// 		background-color: #ffffff;
	// 		border-radius: 50%  50% 0 50% ;
	// 		text-align: center;
	// 		line-height: 80px;
	// 		img{
	// 			vertical-align: middle;
	// 			width: 40px;
	// 			height: 40px;
	// 		}
	// 	}
	// 	.content_name {
	// 		transition: all .5s ease;

	// 		.classcontent {
	// 			display: flex;
	// 			padding-top: 10px;

	// 			.name {
	// 				color: aquamarine;
	// 			}

	// 			.talk {
	// 				color: white;
	// 				max-width: 200px;
	// 			}
	// 		}

	// 	}

	// 	.none {
	// 		height: 0;
	// 		overflow: hidden;
	// 	}

	// 	.show {
	// 		height: 120px;
	// 		overflow-y: scroll;
	// 		// overflow-x: hidden;
	// 	}

	// 	.dialog {
	// 		position: absolute;
	// 		left: -3px;
	// 		top: -20px;
	// 		height: 20px;
	// 		width: 60px;
	// 		text-align: center;
	// 		line-height: 20px;
	// 		color: #000000;
	// 		background-color: #ffffff;
	// 		border-radius: 10px 10px 0 0;
	// 	}
	// 	.dialog1 {
	// 		position: absolute;
	// 		right: -3px;
	// 		top: -30px;
	// 		height: 30px;
	// 		width: 30px;
	// 		text-align: center;
	// 		line-height: 30px;
	// 		color: #000000;
	// 		background-color: #ffffff;
	// 		border-radius: 10px 10px 0 0;
	// 		line-height: 30px;
	// 		img{
	// 			vertical-align: middle;
	// 			width: 60%;
	// 			height: 60%;
	// 		}
	// 	}
		
	// 	.dialogBox {
	// 		font-size: 12px;
	// 		display: flex;
	// 		align-items: center;
	// 		height: 30px;
	// 		width: 100%;
	// 		justify-content: center;

	// 		input {
	// 			width: 95%;
	// 			line-height: 30px;
	// 			background-color: rgba(255, 255, 255, 0.7);
	// 			margin: 15px 0;
	// 			text-indent: 4px;
	// 			border: none;
	// 			margin-right: 2px;
	// 			border: none;
	// 		}

	// 		.btn {
	// 			line-height: 30px;
	// 			width: 20%;
	// 			color: white;
	// 			background: blue;
	// 			border-radius: 5px;
	// 			margin: 5px auto;
	// 			text-align: center;
	// 		}

	// 		.btnnone {
	// 			line-height: 30px;
	// 			width: 20%;
	// 			color: white;
	// 			background: #5d5d5d;
	// 			border-radius: 5px;
	// 			margin: 5px auto;
	// 			text-align: center;
	// 		}
	// 	}
	// }

</style>