export default{
	load:{
		name:'虚拟商城',
		name1:'虚拟世界',
		name2:'峰会',
		name3:'空中花园',
		name4:'企业展厅',
		name5:'会展中心',
		// name:'Virtual Mall',
		// name1:'Virtual World',
		// name2:'Conference Hall',
		// name3:'SkyPark',
		// name4:'Enterprise Showroom',
		// name5:'Exhibition Center',
		btn:'点击进入场景'
	},
	index:{
		alert:'当前人气值',
		firstperson:'第一人称',
		thirdperson:'第三人称',
		avatardIY:'虚拟角色编辑',
		animationrun:'跑',
		animationwalk:'走',
		animationJump:'跳'
	},
	message:{
		mesbtn1:'长按虚拟摇杆并滑动可以移动当前位置',
		mesbtn2:'长按屏幕空白处左右滑动可以移动视角'
	},
	maplist:{
		beautyzone:'美妆区',
		digitalarea:'数码区',
		Jewelrysection:'首饰区' ,
		HomeAppliancearea:'家电区' ,
		Garmentdistrict:'服装区' ,
		emojis:'表情',
		emotes:'动作',
	},
	alert:{
		prompt:'提示',
		message:'您是否要切换到',
		cancel:'取消',
		confirm:'确认'
	},
	virtualDressing:{
		save:'保存',
		title:'虚拟角色编辑器',
		player:'角色',
		Coat:'上衣',
		Trousers:'裤子',
		Eyes:'瞳孔',
		skin:'皮肤',
		hair:'头发',
		eyebrow:'眉毛',
		shoe:'鞋子',
		face:'脸型',
		Cancel:'取消',
		Male:'男',
		Female:'女',
		next:'下一步'
	},
	login:{
		login:'登录',
		Visitorlogin:'游客登录',
		Register:'注册',
		tip:'已有账号？点击登录',
		// title:'Starbox元宇宙平台',
		title:'CONNEX METAVERSE'
		// title:'ARx元宇宙平台'
	},
	tip:{
		tip:'您在私人语音房间'
	},
	userinfo:{
		AvatarDisplayName:'虚拟角色显示名称',
		FirstName:'名字',
		LastName:'姓氏',
		title:'用户资料'
	}
}