import CryptoJS from "crypto-js"
import { ZIM } from "zego-zim-web"
import {ZegoExpressEngine } from 'zego-express-engine-webrtc'

// 文本配置信息
// const appConfig = {
//   appID: 126489912, // AppID - type: number
//   serverSecret: "34dee90ac1e722aa21aa8db78a386d5a", // ServerSecret - type: 32 byte length string
// }
let appConfig = {
  appID: 188544527, // AppID - type: number
  serverSecret: "5f6ac0ee165e71dfa65b6991e09ee186", // ServerSecret - type: 32 byte length string
}
// 语音配置信息
// const zgConfig={
// 	 appID: 126489912, 
// 	 server:'wss://webliveroom152514652-api.imzego.com/ws'
// }
let zgConfig={
	 appID: 188544527, 
	 server:'wss://webliveroom188544527-api.coolzcloud.com/ws'
}
console.log(window.weburl)
if(window.weburl){
	zgConfig={
		 appID: 126489912, 
		 server:'wss://webliveroom152514652-api.imzego.com/ws'
	}
	appConfig = {
	  appID: 126489912, // AppID - type: number
	  serverSecret: "34dee90ac1e722aa21aa8db78a386d5a", // ServerSecret - type: 32 byte length string
	}
}
export const avatarPrefix = "https://storage.zego.im/zim/example/web/assets/"
// 创建ZIM 实例
ZIM.create(appConfig)
//避免多次创建
export const zim = ZIM.getInstance()
zim.setLogConfig({logLevel:'warn' });
export const SDKVersion = ZIM.getVersion()

export const zg = new ZegoExpressEngine(zgConfig.appID,zgConfig.server);
zg.setLogConfig({logLevel:'warn' });
// @ts-ignore
window.zim = zim

// export function throttle(fn, delay = 300) {
//     let preTime = Date.now()//获取选中的时间戳
//     return function () {
//         const context = this
//         let args = arguments
//         let doTime = Date.now()
//         if (doTime - preTime >= delay) {
//             fn.apply(context, args)
//             preTime = Date.now()
//         }
//     }

// }

export function generateToken (userID, seconds) {

  if (!userID) throw new Error("generateToken error: params invalid.")

  const time = (Date.now() / 1000) | 0
  const body = {
    app_id: Number(appConfig.appID),
    user_id: String(userID),
    nonce: (Math.random() * 2147483647) | 0,
    ctime: time,
    expire: time + Number(seconds || 7200),
  }

  const key = CryptoJS.enc.Utf8.parse(appConfig.serverSecret)
  let iv = Math.random().toString().substring(2, 18)
  if (iv.length < 16) iv += iv.substring(0, 16 - iv.length)

  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(body), key, {
    iv: CryptoJS.enc.Utf8.parse(iv),
  }).toString()
  const ciphert = Uint8Array.from(
    Array.from(atob(ciphertext)).map((val) => val.charCodeAt(0))
  )
  const len_ciphert = ciphert.length

  const uint8 = new Uint8Array(8 + 2 + 16 + 2 + len_ciphert)
  // expire: 8
  uint8.set([0, 0, 0, 0])
  uint8.set(new Uint8Array(Int32Array.from([body.expire]).buffer).reverse(), 4)
  // iv length: 2
  uint8[8] = iv.length >> 8
  uint8[9] = iv.length - (uint8[8] << 8)
  // iv: 16
  uint8.set(Uint8Array.from(Array.from(iv).map((val) => val.charCodeAt(0))), 10)
  // ciphertext length: 2
  uint8[26] = len_ciphert >> 8
  uint8[27] = len_ciphert - (uint8[26] << 8)
  // ciphertext
  uint8.set(ciphert, 28)

  const token = `04${btoa(String.fromCharCode(...Array.from(uint8)))}`
  // console.log("generateToken", iv.length, body, token)

  return token
}
