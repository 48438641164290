<template>
	<div v-if="loadflag" class="loadcomme">
		<div class="load">
			<div class="loading" />
			<div v-show="load_percent.flag">{{shownum}}</div>
		</div>
		
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	export default {
		// props: ["loadflag","load_percent"]
		computed: {
			...mapState('VirtualDressing', ['loadflag', 'load_percent']),
			shownum(){
				let num = (this.load_percent.newnum/this.load_percent.allnum)*100
				return num.toFixed(2)+'%'
			}
		},
	};
</script>

<style>
	.loadcomme {
		position: relative;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.2);
		z-index: 10000;
		pointer-events: auto;
	}
	.loadcomme .load {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 80px;
		height: 80px;
		text-align: center;
		color: #959595;
		transform: translate(-50%, -45%);
	}

	.loadcomme  .loading {
		position: relative;
		width: 20px;
		height: 20px;
		margin: 10px auto;
		border: 3px solid #ffffff;
		border-top-color: rgba(0, 0, 0, 0.2);
		border-right-color: rgba(0, 0, 0, 0.2);
		border-bottom-color: rgba(0, 0, 0, 0.2);
		border-radius: 100%;
		animation: circle infinite 0.75s linear;
	}

	@keyframes circle {
		0% {
			transform: rotate(0);
		}

		100% {
			transform: rotate(360deg);
		}
	}
</style>