const walkingSound ={
    namespaced:true,
    state:{
		option:{
			walk:'arweb-virtualshop/ad_media/walk_media/walk.mp3',//走路音频
			run:'arweb-virtualshop/ad_media/walk_media/run.mp3',
			jump:'arweb-virtualshop/ad_media/walk_media/jump.mp3'
			// walk:'/audio/walk.mp3',//走路音频
			// run:'/audio/run.mp3',
			// jump:'/audio/jump.mp3'
		},
		new_media_player:false,//是否播放
		new_media_option:'arweb-virtualshop/ad_media/walk_media/walk.mp3',//需要播放的音频地址
    },
    mutations:{
       changeNewMediaPlayer(state,data){
		   state.new_media_player = data;
	   },
	   changeNewMediaOption(state,data){
		   state.new_media_option = data;
	   }
    }
}
export default walkingSound;