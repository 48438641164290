<!--切换提示信息 -->
<template>
	<div class="alert_all" v-show="alertshow.show&&alertshow.start">
		<!-- <div class="alert_title">{{$t('alert.prompt')}}</div> -->
		<div class="alert_content">{{$t('alert.message')}} {{alertshow.toname}}?</div>
		<div class="alert_btn">
			<div @click="changeshow">{{$t('alert.cancel')}}</div>
			<div @click="changsence">{{$t('alert.confirm')}}</div>
		</div>
	</div>
</template>

<script>
	import { mapState,mapMutations } from 'vuex'
	export default{
		data(){
			return{
				
			}
		},
		computed:{
			...mapState(['alertshow'])
		},
		methods:{
			...mapMutations(['changealertshow','changealertstart']),
			changeshow(){
				this.changealertshow(false)
				this.changealertstart(false)
			},
			changsence(){
				// this.$emit("changscene",true)
				this.$router.replace(this.alertshow.toobj.toroute)
				this.changeshow()
			}
		}
	}
</script>

<style lang="less" scoped>
	.alert_all{
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		background-color: white;
		padding: 20px;
		padding-top: 0;
		border-radius: 5px;
		width: 220px;
		pointer-events: auto;
		z-index: 1000000;
		.alert_title{
			line-height: 50px;
			font-size: 16px;
			color: black;
			font-weight: 600;
			border-bottom: 1px solid #a5a5a5;
			margin-bottom: 10px;
		}
		.alert_content{
			font-size: 14px;
			color: black;
			line-height: 25px;
			margin: 10px;
			text-align: center;
		}
		.alert_btn{
			display: flex;
			justify-content:center;
			margin-top: 20px;
			div{
				width: 60px;
				line-height: 25px;
				text-align: center;
				background-color: #0055ff;
				border-radius: 20px;
				font-size: 12px;
				margin-left: 10px;
			}
			div:nth-of-type(1){
				background-color: white;
				border: 1px solid #939393;
				color: #939393;
			}
		}
	}
</style>