// 引入axios
import axios from 'axios'

// export let url = "https://nodeserver.argamemap.com:90"
// let url = "http://1.14.208.30:81"\
 axios.defaults.baseURL  =  "https://nodeserver-arx.argamemap.com"
console.log(window.weburl)
if(window.weburl){
	axios.defaults.baseURL  =  "https://nodeserver.argamemap.com"
	// axios.defaults.baseURL  = "/apinode"
}

//创建实例
const service = axios.create({
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
}) 
// request interceptor
service.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  return Promise.reject(error);
})

// response interceptor
service.interceptors.response.use(function (response) {
  return response.data
}, function (error) {
  return Promise.reject(error);
})

export default service
