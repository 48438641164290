import {getgate,getconnector,adduserchannel}  from '@/request/pinus.js'
let pinus = window.pinus;
const dialog ={
    namespaced:true,
    state:{
	// type  other 其他人  self   通知 inform
       dialoglist:[{
			name: 'admin',
			// content: 'Welcome to Starbox Virtual World!',
			content: 'Welcome to Virtual World!',
			type:'public',
			clientSid:-1
       },],
	   publicRoom:'public',
	   // auidoflag:false,//是否接受别人的语音
	   // 当前选中的name
	   chatchannl:'',
	   // 私人聊天数组
	   chatPrivateArr:[{
		   name:'public',//显示名称
		   publicChannel:true,//是否为公共的
		   title:'public',//左上叫显示的名称（房间）
		   toobject:null,
		   deleteflag:false,//是否可以删除对话
		   newinformation:false,
	   }]
	   
    },
    mutations:{
		addplayerpublic(state,obj){
			state.chatchannl = obj
			adduserchannel(sessionStorage.getItem('oneidname'),obj.publicChannel?obj.name:obj.Channel,obj.publicChannel)
		},
		changechatchannl(state,obj){
			let newchatPrivateArr = [...state.chatPrivateArr]
			let index =newchatPrivateArr.findIndex(item=>{return item.name == obj.name})
			newchatPrivateArr[index]['newinformation'] = false
			state.chatchannl = obj
			state.chatPrivateArr = newchatPrivateArr
			// ConstantSourceNode.length
		},
        changedialoglist(state,obj){
			console.log(state.dialoglist,obj)
			if(!state.dialoglist.some(item=>{ return item.clientSid == obj.clientSid})){
				state.dialoglist =[...state.dialoglist,obj]
				setTimeout((clientSid)=>{
					state.dialoglist =state.dialoglist.filter(item=>{
						return item.clientSid != clientSid
					})
				},10000,obj.clientSid)
				let chatPrivateArr = state.chatPrivateArr
				let newchatPrivateArr =[];
				// 判断当前列表是否有这个人
				let userflag = false;
				// 判断这是不是我发的消息 和当前已经打开对话栏就不提醒
				if(state.chatchannl.name == obj.name||obj.name ==sessionStorage.getItem('username') ) return
				// let user = chatPrivateArr.filter(item=>{return item.name == obj.title})
				for(let i=0; i<chatPrivateArr.length ;i++ ){
					if(chatPrivateArr[i].name == obj.name ){
						let newobj ={...chatPrivateArr[i],newinformation:true}
						newchatPrivateArr.push(newobj)
						userflag = true
					}else{
						newchatPrivateArr.push(chatPrivateArr[i])
					}
				}
				if(!userflag  &&obj.name != chatPrivateArr[0].name && !obj.type || (obj.type&&obj.toobject&&!userflag)){
					let newuser = {
						name:obj.name,//显示名称
						publicChannel:obj.type,//是否为公共的
						title:obj.name,//左上叫显示的名称（房间）
						toobject:obj.name,
						deleteflag:true,//是否可以删除对话
						newinformation:state.chatchannl != obj.title,
					}
					newchatPrivateArr =[...chatPrivateArr];
					newchatPrivateArr.splice(1,0,newuser) 
				}
				state.chatPrivateArr = newchatPrivateArr;
			}
		},
		// 删除对话
		changechatPrivateArrDele(state,obj){
			let newchatPrivateArr=state.chatPrivateArr.filter((item)=>{return item.name != obj.name})
			state.chatPrivateArr =newchatPrivateArr
			state.chatchannl = state.chatPrivateArr[0]
		},
		changechatPrivateArr(state,obj){
			let chatPrivateArr = state.chatPrivateArr
			let index =chatPrivateArr.findIndex(item=>{return item.name == obj.name})
			if(index != -1){
				state.chatchannl = obj
			}else if(state.chatPrivateArr.length <4){
				let obj1 = [...state.chatPrivateArr]
				obj1.splice(1,0,obj) 
				state.chatPrivateArr =obj1
				state.chatchannl = obj
			}else{
				let newobj = [...state.chatPrivateArr]
				let deleindex = newobj.length;
				for(let i=newobj.length-1 ; i>0 ;i--){
					// console.log(newobj[i].deleteflag)
					if(newobj[i].deleteflag){
						deleindex = i
						break;
					}
				}
				
				newobj.splice(deleindex,1) 
				newobj.splice(1,0,obj) 
				state.chatPrivateArr =newobj
				state.chatchannl = obj
				// console.log(deleindex,newobj)
			}
			// if(state.chatPrivateArr.filter((item)=>{return item.name == state.chatchannl}).length ==0){
				
				
			// }
			
		},
    },
	actions:{
		// 加入房间，开启监听
		
		// 监听信息信息
		onListenerZIM(context){
			// 有人加入公共会话
			pinus.on('onAddChannel', (data)=> {
				// console.log(data)
			});
			pinus.on('sendroomChannel', (data)=> {
				// console.log(222,data)
				context.commit('changedialoglist',data)
			});
			pinus.on('onLeavediaChann', (data)=> {
				// console.log(222,data)
			});
			// 私人会话
			// 有人离开了会话
			// 有人在当前会话中发送了信息
			
			// 	return zim.on('receiveRoomMessage', function (zim, res) {
			// 		console.log(222,zim,res)
			// 		for(let i=0;i<res.messageList.length;i++){
			// 			let obj = {
			// 				name:res.messageList[i].senderUserID,
			// 				content:res.messageList[i].message,
			// 				type:'Other',
			// 				clientSid:new Date().getTime()
			// 			}
			// 			context.commit('changedialoglist',obj)
			// 		}
					
					
			// 	});
		}
	}
}
export default dialog;