import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login/Login.vue'
// import login from '@/l=components'
import store from '@/store'
Vue.use(VueRouter)
const router = new VueRouter({
	routes:[
		{
			path:'/',
			redirect:'/block',
			meta: {place:store.state.placeInfo.placeInfo[1] }
	    },
		{
			path:'/login',
			name:"login",
			component:login,
			meta: { }
		},
		{
			path:'/block',
			name:'block',
			component:()=>import('../views/block/HelloWorld.vue'),
			meta: {place:store.state.placeInfo.placeInfo[0]  }
		},
		{
			path:'/mark',
			name:'mark',
			component:()=>import('../views/Marketplace/HelloWorld.vue'),
			meta: {place:store.state.placeInfo.placeInfo[1]  }
		},
		{
			path:'/dressing',
			name:'Dressing',
			component:()=>import('../views/VirtualDressing/Dressing.vue'),
			meta: {}
		},
		{
			path:'/conference',
			name:'Conference',
			component:()=>import('../views/conferenceHall/HelloWorld.vue'),
			meta: {place:store.state.placeInfo.placeInfo[2]  }
		},
		{
			path:'/SkyPark',
			name:'SkyPark',
			component:()=>import('../views/SkyPark/HelloWorld.vue'),
			meta: {place:store.state.placeInfo.placeInfo[3]  }
		},
		{
			path:'/Showroom',
			name:'Enterprise Showroom',
			component:()=>import('../views/Showroom/HelloWorld.vue'),
			meta: {place:store.state.placeInfo.placeInfo[4]  }
		},
		{
			path:'/ExhibitionCenter',
			name:'Exhibition Center',
			component:()=>import('../views/ExhibitionCenter/HelloWorld.vue'),
			meta: {place:store.state.placeInfo.placeInfo[5]  }
		}
	]
})
//设置可以不用权限进入的路由
const noroute = ['/login']
router.beforeEach((to, form, next) => {
	store.commit('changloadnum', 0)
	store.commit('changloading', true)
	store.commit('changeisenter', false)
	
	//判断是否需要权限
	if (store.state.user.oneidname || noroute.includes(to.path)) {
		store.commit('changelastrouter', form.path)
		to.meta.place&&store.commit('placeInfo/changenowinitplace', to.meta.place.place)
		// 重置表情参数
		store.commit('user/changechangeplayerEmtoeArr',null)
		next()
	//判断是否登录
	}else{
		next("/login")
	}

})
export default router